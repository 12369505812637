import { publicPath } from "@/common/constant.js";
import axios from "axios";
import {
  findAdministrativeCombobox,
  findBankInfoCombobox,
  findBranchCompanyCombobox,
  findCityLevelComboboxx,
  findCompanyBaseInfo,
  findCooperationModel,
  findDictionaryCombobox,
  findJointBankNumber,
  findMaterialGroupCombobox,
  findSalesAreaCombobox,
  findSubChannelCategoryCombobox,
  focusonremarkplz,
  getCiseCode,
  groupSecondaryLabel,
  tmpSave,
  findRegionByProvinceId,
  detail,
  findBankAccountDetail,
  findCompanyViewDetail,
  findSalesViewDetail,
  findTradeRelationDetail,
  getThirdLabelBySecondLabel,
  MaterialCompany,
  flowStart,
  stepBuildStoreParamCheck,
  getBDList,
  getBUList
} from "./api";
import {
  setStoreUser,
  setStoreUser1,
  delStoreUser,
  delStoreUser1,
  getStoreUser,
  setqystatus,
  getqystatus,
} from "@/utils/store";

export default {
  name: "index",
  data() {
    return {
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: this.$route.query.url === 'jurisdictionalMerchants'?'/jurisdiction/merchants':'/basicInfo?type=3',
          name: this.$route.query.url === 'jurisdictionalMerchants'?'/jurisdiction/merchants':'/basicInfo?type=3',
          title: this.$route.query.url === 'jurisdictionalMerchants'?'管辖的商家': '合作的分销商',
        },
        {
          path: "/newMerchantaddEdit",
          name: "newMerchantaddEdit",
          title:
            (this.$route.query.name == "view"
              ? " 查看"
              : this.$route.query.type == "edit"
              ? "编辑"
              : "新增") + "分销商",
        },
      ],
      ifshow: false,
      spinning: false, //页面整体loading
      editCount: 0, //表单编辑次数 >1则有编辑
      menuList: [
        {
          title: "基础信息",
          active: true,
        },
        {
          title: "工商注册信息",
          active: false,
        },
        {
          title: "银行账号",
          active: false,
        },
        {
          title: "上传三证",
          active: false,
        },
        {
          title: "贸易关系",
          active: false,
        },
        // {
        //   title: "销售视图",
        //   active: false
        // },
        // {
        //   title: "公司视图",
        //   active: false
        // },
        // {
        //   title: "银行账号",
        //   active: false
        // }
        // {
        //   title: "商家历史关系",
        //   active: false
        // }
      ],
      visible1: false, //建店弹窗
      visible2: false, //建仓弹窗
      visible3: false, //提交弹窗
      isEdit: false, //是否点击编辑按钮
      branchOfficeid: "", //分公司id
      EditIndex: "", //编辑获取对应行下标
      addmessage: false, // 贸易关系添加信息模块是否显示
      isFixed: false, //左侧是否跟随
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      scrollWatch: true, //滚动flag
      editid:"",
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      checkRule: false, // 用来判断所属家居、合作性质是否是必填
      rules: {
        // 分销商全称
        shopName: [
          { required: true, message: "请填写分销商全称", trigger: "blur" },
        ],
        // 分销商简称
        jcName: [
          { required: true, message: "请填写分销商简称", trigger: "blur" },
           {
            max: 10,
            message: "分销商简称需小于等于10个字",
            trigger: "blur",
          },
        ],
        qyStatus: [
          // { required: true, message: "请填写企业状态", trigger: "blur" },
          // {
          //   trigger: "blur",
          // },
        ],
        // 基础业务信息字段
        //市场级别
        MarketLevel: [
          { required: true, message: "请选择市场级别", trigger: "blur" },
        ],
        // 邮箱
        email: [
          { required: true, message: "请填写邮编", trigger: "blur"},
          // { min: 6, max: 6, message: "请输入正确的邮政编码", trigger: "blur" },
          {
            validator: this.$util.ZipCodeValidator,
            trigger: 'blur',
          },
        ],
        // 渠道类别
        channelLevel: [
          { required: true, message: "请选择渠道类别", trigger: "blur" },
        ],
        // 渠道类别明细
        channelLevelSegmentation: [
          { required: true, message: "请选择渠道类别明细", trigger: "blur" },
        ],
        // BD
        bdId: [
          { required: true, message: "请选择BD", trigger: "blur" },
        ],
        // BU
        bu1Id: [
          { required: true, message: "请选择BU", trigger: "blur" },
        ],
        // 合作性质
        cooperativeNatureId: [
          { required: false, message: "请选择合作性质", trigger: "blur" },
        ],
        // 所属家居
        householdNatureId: [
          { required: false, message: "请选择所属家居", trigger: "blur" },
        ],
        //集团一级标签
        groupLevelLabel: [
          { required: true, message: "请选择集团一级标签", trigger: "blur" },
        ],
        //集团二级标签
        groupSecondaryLabel: [
          { required: true, message: "请选择集团二级标签", trigger: "blur" },
        ],
        //城市级别
        cityLevel: [
          { required: true, message: "请选择城市级别", trigger: "blur" },
        ],
        // 商家类别
        MerchantCategory: [
          { required: true, message: "请选择商家类别", trigger: "blur" },
        ],
        // 网点类别
        NetworkType: [
          { required: true, message: "请选择网点类型", trigger: "blur" },
        ],
        // annualContractAmount: [
        //   { required: true, message: "请填写签约提货额/万元", trigger: "blur" }
        //
        // ],
        //  222
        province: [{ required: true, message: "请选择省", trigger: "blur" }],
        city: [{ required: true, message: "请选择市", trigger: "blur" }],
        area: [{ required: true, message: "请选择区", trigger: "blur" }],
        Township: [
          { required: true, message: "请选择乡镇街道", trigger: "blur" },
        ],
        applyReason: [
          { required: true, message: "请填写申请原因", trigger: "blur" },
        ],
        Telephone: [{ required: true, message: "请填写电话", trigger: "blur" }],
        bossPhone: [
          { required: true, message: "请填写老板手机号", trigger: "blur" },
          {
            validator: this.$util.MobileNumberValidator,
            trigger: 'blur',
          },
        ],
        corporateStockholder: [
          { required: true, message: "请填写公司股东", trigger: "blur" },
        ],
        officeAddress: [
          { required: true, message: "请填写办公地址", trigger: "blur" },
        ],
        kehuquan: [
          { required: true, message: "请填写客户全称", trigger: "blur" },
        ],
        keyPersonnel: [
          { required: true, message: "请填写主要人员", trigger: "blur" },
        ],
        companyRegisteredAddress: [
          { required: true, message: "请填写注册地址", trigger: "blur" },
        ],
        legalPerson: [
          { required: true, message: "请填写法人", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请填写银行名称", trigger: "blur" },
        ],
        accountHolder: [
          { required: true, message: "请填写账号持有人", trigger: "blur" },
        ],
        bankAccount: [
          { required: true, message: "请填写银行账号", trigger: "blur" },
          {
            validator: this.$util.NumberValidatorBank,
            trigger: 'blur',
          },
          // {
          //   min: 16,
          //   max: 19,
          //   message: "请输入正确的银行账号",
          //   trigger: "blur",
          // },
        ],
        taxClassification: [
          { required: true, message: "请选择税分类", trigger: "blur" },
        ],
        IdentificationNumber: [
          {
            required: true,
            message: "请填写税务登记证",
            trigger: "blur",
          },
        ],
        SocialUnifiedCredit: [
          { required: true, message: "请填写社会统一信用码", trigger: "blur" },
        ],
      },
      maoyirules: {
        // MaterialGroup: [
        //   { required: true ,message: "请选择物料组",trigger: "blur"}
        // ],
        // MerchantManagerContact: [
        //   { required: true, message: "请填写商家经理联系人", trigger: "blur" }
        // ],
        // MerchantManagerContactPhone: [
        // { required: true, message: "请填写商家经理电话", trigger: "blur" },
        // {
        //   validator: this.$util.MobileNumberValidator,
        //   trigger: 'blur',
        // },
        // ]
      },
      // 表单数据
      formTable: {
        shopName: "", // 商家全称
        jcName: "", //商家简称
        qyStatus: "", // 企业状态
        // 基础业务信息字段
        cisCode: "", //cis编码
        MDMCode: "", // mdm编码
        MarketLevel: "", // 市场级别
        email: "", // 邮编
        channelLevel: "", // 渠道类别
        channelLevelSegmentation: "", // 渠道类别明细
        bdId: "", // BD
        bu1Id: "", // BU
        groupLevelLabel: "", // 集团一级标签
        groupSecondaryLabel: "", // 集团二级标签
        thirdLabelId: "", // 渠道三级标签
        Region: "", // 所属大区
        RegionId: "", // 所属大区id
        cityLevel: "", //城市级别
        MerchantCategory: [], // 商家类别
        NetworkType: "", //网点类型
        //  2222
        IdentificationNumber: "", // 税务登记证
        businessLicense: "", //营业执照
        idCard: "", //身份证
        RegistrationNumber: "", // 工商注册号
        SocialUnifiedCredit: "", // 统一社会信用码
        EnterpriseType: "", // 企业类型
        //  3333
        province: "", // 省
        city: "", // 市
        area: "", // 区
        Township: "", //乡镇
        Telephone: "", // 电话
        bossPhone: "", // 老板手机号
        corporateStockholder: "", // 公司股东
        officeAddress: "", // 办公地址
        keyPersonnel: "", // 主要人员
        companyRegisteredAddress: "", // 注册地址
        legalPerson: "", //法人
        bankName: undefined, // 银行名称
        accountHolder: "", // 账号持有人
        bankAccount: "", // 银行账号
        taxClassification: "", // 税分类
        accountGroup: "集团外", // 账户组
        accountGroupId: "405512701", // 账户组id
        applyReason: "", // 申请原因
        // annualContractAmount: "",//年度签约提货额
        householdNatureId: "", //所属家居
        cooperativeNatureId: "", //合作性质
        processInstanceId:""//驳回编辑id
      },
      // 企业数据窗
      enterPriseData: {
        cisCode: "", // CIS编码
        MDMCode: "", // MDM编码
        taxNumber: "", // 纳税人识别号
        name: "", // 商家全称
        companyOrgType: "", // 企业类型
        regStatus: "", // 企业状态
        alias: "", // 商家简称
        property3: "", // 商家英文名
        isMicroEnt: "", //是否小微企业
        percentileScore: "", //企业评分
        regNumber: "", //注册号
        creditCode: "", //统一设计信用代码
        legalPersonName: "", //法人
        type: "", //法人类型
        historyNames: "", //曾用名
        bondNum: "", //股票号
        bondName: "", //股票名
        stockNameUsed: "", //股票曾用名
        id: "", //企业ID
        industry: "", //行业
        enterpriseLabel: "", //企业标签
        regCapital: "", //注册资本
        regCapitalCurrency: "", //注册资本币种
        actualCapital: "", //实收注册资本币种
        regInstitute: "", //登记机关
        establishTimeStr: "", //成立时间
        approvedTimeStr: "", //核准时间
        updateTimesStr: "", //更新时间
        regLocation: "", //注册地址
        orgNumber: "", //组织机构代码
        bondType: "", //股票类型
        fromTimeStr: "", //经营开始时间
        operationEndTimeStr: "", //经营结束时间
        staffNumRange: "", //人员规模
        socialStaffNum: "", //参保人数
        revokeDateStr: "", //吊销日期
        revocationReason: "", //吊销原因
        logoutDateStr: "", //注销日期
        cancellationReason: "", //注销原因
        provinceAbbreviation: "", //省份简称
        city: "", //市
        county: "", //区县
        businessScope: "", //经营范围,
      },
      isbianji: false, //是否编辑
      groupThreeList: [], // 渠道三级列表
      addressHx: [], //地址默认值
      businessLicense: [], // 营业执照附件
      businessLicenseId: {
        id: "",
      }, // 营业执照附件id
      appendixFileList: [], // 三证合一
      appendixFileId: {
        id: "",
      }, // 三证合一附件id
      taxFileList: [], // 税务登记证
      taxFileId: {
        id: "",
      }, // 税务登记证附件id
      otherFileList: [], // 其他附件
      otherFileId: [], // 其他附件id
      onlyone:false,//只能上传一个其他附件
      mechanismFileList: [], // 组中机构代码证
      mechanismFileId: {
        id: "",
      }, // 组中机构代码证附件id
      //提交禁用
      tijiaoable: false,
      // 贸易关系---分公司下拉列表
      branchesList: [],
      // 贸易关系---物料组
      materialList: [],
      bianjiaddressHx: [], //点击编辑，所在地区的值
      // 贸易关系---营销模式
      MarketingModelList: [
        {
          code: "17451",
          name: "分销",
        },
      ],
      // 贸易关系---上级代理
      agentList: [],
      //贸易关系---合作模式
      CooperationTypeList: [],
      //贸易关系---业务类型
      businessList: [],
      // 贸易关系---合作状态
      CooperationStatusList: [
        {
          id: "1",
          name: "合作",
        },
      ],
      freezeMarkList: [
        {
          id: "1",
          name: "合作",
        },
      ],
      agentSelctRow: {},
      agentSearchStr: "", // 上级代理模糊搜索关键字
      selectedRowKeys: [], // 贸易关系 选中的数据数组
      // 贸易关系表格头
      columns: [
        {
          title: "分公司",
          dataIndex: "branches",
          width: 150,
          scopedSlots: { customRender: "branches" },
        },
        {
          title: "物料组",
          dataIndex: "materialGroup",
          width: 150,
          scopedSlots: { customRender: "materialGroup" },
        },
        {
          title: "营销模式",
          dataIndex: "MarketingModel",
          width: 150,
          scopedSlots: { customRender: "MarketingModel" },
        },
        {
          title: "上级代理",
          dataIndex: "superiorAgent",
          width: 220,
          scopedSlots: { customRender: "superiorAgent" },
        },
        {
          title: "合作模式",
          dataIndex: "CooperationMode",
          width: 150,
          scopedSlots: { customRender: "CooperationMode" },
        },
        {
          title: "业务类型",
          dataIndex: "businessType",
          width: 200,
          scopedSlots: { customRender: "businessType" },
        },
        {
          title: "商家经理联系人",
          dataIndex: "concat",
          width: 160,
          scopedSlots: { customRender: "concat" },
        },
        {
          title: "商家经理联系人电话",
          dataIndex: "concatPhone",
          width: 180,
          scopedSlots: { customRender: "concatPhone" },
        },
        {
          title: "合作状态",
          dataIndex: "CooperationStatus",
          width: 150,
          scopedSlots: { customRender: "CooperationStatus" },
        },
        {
          title: "订单冻结标识",
          dataIndex: "freezeMark",
          width: 150,
          scopedSlots: { customRender: "freezeMark" },
        },
        // {
        //   title: "订单冻结时间",
        //   dataIndex: "freezeTime",
        //   width: 400,
        //   scopedSlots: { customRender: "freezeTime" }
        // }
      ],
      // 获取物料组--新
      getallMaterialGroup: [],
      // 存储物料组数据
      MaterialGroup: "",
      //物料组编码
      MaterialGroupcode: "",
      // 商家经理联系人--新
      MerchantManagerContact: "",
      //商家经理联系人--新
      MerchantManagerContactPhone: "",
      customerMarketModelId:'',
      // 贸易关系列表数据--新
      newtableData: [],

      // 贸易关系列表数据
      tableData: [],
      //  销售视图 表格中据数组
      selectedRowKeysSale: [],
      //获取缓存的数据
      huancundata: [],
      //缓存数据的地区默认值
      huancunaddressHx: [],
      //缓存显示
      huancunview: false,
      // 销售视图表格表头
      saleColumns: [
        {
          title: "销售组织",
          dataIndex: "orgName",
          width: 150,
          scopedSlots: { customRender: "saleOrg" },
        },
        {
          title: "销售地址",
          dataIndex: "saleAddressName",
          width: 150,
          scopedSlots: { customRender: "saleAddress" },
        },
        {
          title: "价格组",
          dataIndex: "priceName",
          width: 190,
          scopedSlots: { customRender: "priceGroup" },
        },
        {
          title: "付款条件",
          dataIndex: "payName",
          width: 330,
          scopedSlots: { customRender: "payTerms" },
        },
        {
          title: "销售冻结标识",
          dataIndex: "saleFreezeName",
          width: 150,
          scopedSlots: { customRender: "saleFreeze" },
        },
      ],
      // 销售视图数据
      saleTableData: [],
      // 销售组织
      orgList: [],
      // 销售地址
      SaleAddressList: [],
      // 价格组
      priceList: [],
      // 付款条件
      payList: [],
      // 销售冻结标识
      saleFreezeList: [
        {
          id: "1",
          name: "合作",
        },
      ],

      // 公司视图
      // 公司视图 表格中据数组
      selectedRowKeysCompany: [],
      //   公司视图表格表头
      companyColumns: [
        {
          title: "公司名称",
          dataIndex: "companyName",
          width: 150,
          scopedSlots: { customRender: "company" },
        },
        {
          title: "统御科目",
          dataIndex: "ControlSubjectName",
          width: 180,
          scopedSlots: { customRender: "ControlSubject" },
        },
        {
          title: "选择规则",
          dataIndex: "SelectionRulesName",
          width: 230,
          scopedSlots: { customRender: "SelectionRules" },
        },
        {
          title: "付款条件",
          dataIndex: "termPaymentName",
          width: 300,
          scopedSlots: { customRender: "termPayment" },
        },
        {
          title: "账户备注",
          dataIndex: "accountNotesName",
          width: 150,
          scopedSlots: { customRender: "accountNotes" },
        },
        {
          title: "记账冻结标识",
          dataIndex: "BookkeepingFreezeName",
          width: 150,
          scopedSlots: { customRender: "BookkeepingFreezeID" },
        },
      ],
      // 公司视图数据
      companyTableData: [],
      // 公司名称
      companyList: [],
      // 统御科目
      SubjectList: [],
      //规则
      RulesList: [
        {
          id: "1",
          name: "rule",
        },
      ],
      // 付款条件
      TermPaymenList: [
        {
          id: "1",
          name: "32323",
        },
      ],
      // 冻结标识
      BookkeepingFreezeList: [
        {
          id: "1",
          name: "合作",
        },
      ],

      //  银行视图
      // 银行视图 表格中据数组
      selectedRowKeysBank: [],
      //  银行视图表格表头
      BankColumns: [
        {
          title: "银行名称",
          dataIndex: "bankName",
          width: 340,
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: "联行号",
          dataIndex: "bankNumber",
          width: 200,
          scopedSlots: { customRender: "bankNumber" },
        },
        {
          title: "银行账户",
          dataIndex: "bankAccount",
          width: 250,
          scopedSlots: { customRender: "bankAccount" },
        },
        {
          title: "账号持有人",
          dataIndex: "accountHolder",
          width: 180,
          scopedSlots: { customRender: "accountHolder" },
        },
        {
          title: "是否为开票账户",
          dataIndex: "billingAccount",
          width: 150,
          scopedSlots: { customRender: "billingAccount" },
        },
      ],
      // 银行视图数据
      BankTableData: [],
      // 银行列表
      bankList: [],
      bankSearchStr: "", // 银行名称模糊搜索
      bankSearchStr1: "",
      bankList1: [],
      fetching: false,
      //是否为开票账户
      billingAccountList: [
        {
          id: "否",
          name: "否",
        },
        {
          id: "是",
          name: "是",
        },
      ],

      //  商家历史关系
      selectedRowKeysHRM: [],
      //  商家历史关系视图表格表头
      HRMColumns: [
        {
          title: "分公司",
          dataIndex: "branches",
          width: 400,
          scopedSlots: { customRender: "branches" },
        },
        {
          title: "物料组",
          dataIndex: "materialGroup",
          width: 400,
          scopedSlots: { customRender: "materialGroup" },
        },
        {
          title: "上一任商家编码/名称",
          dataIndex: "MerchantCode",
          width: 400,
          scopedSlots: { customRender: "MerchantCode" },
        },
        {
          title: "数据初始日期",
          dataIndex: "StartTime",
          width: 400,
          scopedSlots: { customRender: "StartTime" },
        },
        {
          title: "数据截止日期",
          dataIndex: "endTime",
          width: 400,
          scopedSlots: { customRender: "endTime" },
        },
        {
          title: "规模百分比",
          dataIndex: "scale",
          width: 400,
          scopedSlots: { customRender: "scale" },
        },
      ],
      // 商家历史关系视图数据
      HRMTableData: [],
      // 上一任商家编码/名称
      MerchantList: [],
      visible: false,
      loading: false,

      tradeRelationVOList1: [], //贸易视图重组
      tradeRelationVOList2: [], //浏览器存储贸易关系数据
      shopNameShow: false,
      jcNameShow: false,
      shengList: [], // 省
      cityList: [], // 市
      areaList: [], // 区
      TownList: [], //街道
      isLoginModalShow: false, //弹窗是否显示
      giveindex: "", //传递index
      MarkctTypeList: [], // 市场级别
      DmsTypeList: [], // 渠道类别
      DMSxfList: [], //渠道类别细分
      BDList: [], // 渠道类别
      BUList: [], //渠道类别细分
      groupLevelList: [], // 集团一级标签
      groupSecondList: [], //二级标签集合
      CityTypeList: [], // 城市级别
      DictionaryList: [], // 商家类别
      NetworkTypeList: [], //网点类型
      shuiList: [], // 税分类
      spinningInfo: false,
      Bloading: false,
      saveLoading: false,
      householdNatureList: [],
      cooperativeNatureList: [],
      haveId: false,
      alldata: [],
      isview: false, //点击查看禁用表单
      isLoading:'',
    };
  },
  watch: {
    "formTable.shopName": function(val) {
      this.formTable.shopName = val.trim();
    },
  },

  destroyed() {
    this.scrollWatch = false;
  },
  mounted() {
   
    this.branchOfficeid = this.MaterialGroupcode.slice(12);
     if (window.name == "" && this.$route.query.category != "step") {
      delStoreUser();
      delStoreUser1();
      window.name = "isReload"; // 在首次进入页面时我们可以给window.name设置一个固定值
    } else if (
             window.name == "isReload" &&
             this.$route.query.category != "step"
           ) {
             delStoreUser();
             delStoreUser1();
           }
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    // window.addEventListener("scroll", this.handleScroll);
    $(window).on("scroll", this.handleScroll);
    // this.personnelRecordsManagementDetail();
    if (this.$route.query.name == "view") {
      this.isview = true;
    }
    if (getStoreUser() != "") {
      this.huancundata.push(getStoreUser());
      var data = this.huancundata[0].distributorInfoVO;
      this.huancunview = true;
      this.formTable.shopName = data.customerFullName;
      this.formTable.jcName = data.customerShortName;
      this.formTable.qyStatus = getqystatus();
      this.huancunaddressHx = [
        data.provinceId,
        data.cityId,
        data.countyId,
        data.townId,
      ];
      this.formTable.province = data.provinceId;
      this.formTable.city = data.cityId;
      this.formTable.area = data.countyId;
      this.formTable.Township = data.townId;
      this.formTable.companyRegisteredAddress = data.address;
      this.formTable.officeAddress = data.officeAddress;
      this.formTable.email = data.postalCode;
      this.formTable.NetworkType = data.networkTypeId;
      this.formTable.cityLevel = data.districtType;
      this.formTable.MarketLevel = data.marketLevelId;
      this.formTable.bossPhone = data.bossTel;
      this.formTable.channelLevel = data.primaryChannelId;
      this.getDMSxf(this.formTable.channelLevel);
      this.formTable.channelLevelSegmentation = data.subChannelId;
      this.formTable.bdId = data.bdId ? data.bdId + "" : "";
      this.getBUList(this.formTable.bdId);
      this.formTable.bu1Id = data.bu1Id ? data.bu1Id + "" : "";
      this.formTable.RegistrationNumber = data.businessRegistrationNo;
      this.formTable.IdentificationNumber = data.taxCode;
      this.formTable.EnterpriseType = data.enterpriseType;
      this.formTable.SocialUnifiedCredit = data.unifiedSocialCreditCode;
      this.formTable.legalPerson = data.legalPerson;
      this.formTable.bankName = data.bankName;
      this.formTable.accountHolder = data.accountHolder;
      this.formTable.bankAccount = data.bankAccount;
      this.formTable.keyPersonnel = data.keyPersonnel;
      this.formTable.corporateStockholder = data.majorPeople;

      if (data.threeInOneAttachment && data.threeInOneAttachment.id) {
        this.appendixFileList.push({
          uid: data.threeInOneAttachment.id,
          name: data.threeInOneAttachment.attachName,
          url: data.threeInOneAttachment.attachPath,
          name2: "旧的",
        });
        // 三证合一id
        this.appendixFileId.id = data.threeInOneAttachment.id;
      }
      if (data.threeInOneAttachment &&data.otherAttachmentList.length > 0) {
        data.otherAttachmentList.forEach((i) => {
          this.otherFileList.push({
            uid: i.id,
            name: i.attachName,
            url: i.attachPath,
            name2: "旧的",
          });
          // 其他附件
          this.otherFileId.push({
            id: i.id,
          });
        });
      }
      if (data.tradeRelationVOList.length > 0) {
        data.tradeRelationVOList.forEach((i) => {
          this.newtableData.push({
            BranchOfficeid:i.branchOfficeid,
            MaterialGroup: i.materialGroupBranchCompany2,
            MerchantManagerContact: i.customerManager,
            MerchantManagerContactPhone: i.customerManagerTel,
            MaterialGroupcode: i.materialGroupBranchCompany
            
          });
        });
      }
    }

    this.$nextTick(() => {
      this.getMarkctType();
      this.getDmsType();
      this.getBDList();
      this.getDMSxf();
      this.getgroupLevelLabel();
      this.getCityType();
      this.getDictionaryCom();
      this.getNetworkType();
      this.getshuiList();
      this.getShengList();
      this.getFGS();
      this.getYWLX();
      this.getAgentList();
      this.getPriceList();
      this.getpayList();
      this.getSubject();
      this.getRulesList();
      this.getMaterialCompany();
      if (this.$route.query.id) {

        this.detail(this.$route.query.id);
        this.getMyTable(this.$route.query.id);
        this.getSaleInfo(this.$route.query.id);
        this.getCompanyInfo(this.$route.query.id);
        this.getBankInfo(this.$route.query.id);
        this.haveId = true;
      }
      // this.getCisCode();


    });
  },
  methods: {
    // 查询合作性质
    getCooperativeNature() {
      let data = {
        pid: 14171900250,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.cooperativeNatureList = res.data.list;
        }
      });
    },
    // 查询所属家居
    gethouseholdNature() {
      let data = {
        pid: 14171900260,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.householdNatureList = res.data.list;
        }
      });
    },
      cancle() {
      this.$emit("update:visible", false);
      this.$forceUpdate()

    },
    // 编辑
    // 获取表单详情
    detail(id) {
      this.spinning = true;
      let data = {
        id: id,
      };
      if (this.$route.query.type == "edit") {
        this.isbianji = true;
      }
      detail(data).then((res) => {
        this.addressHx = [
          String(res.data.data.provinceId),
          String(res.data.data.cityId),
          String(res.data.data.countyId),
          String(res.data.data.townsId),
        ];
        this.bianjiaddressHx = [
          String(res.data.data.provinceId),
          String(res.data.data.cityId),
          String(res.data.data.countyId),
          String(res.data.data.townsId),
        ];
        
        this.editid=res.data.data.id
        if (res.data.code == 0) {
          // 公司全称
          this.formTable.shopName = res.data.data.fullName;
          // 公司简称
          this.formTable.jcName = res.data.data.searchTerm;
          // 统一社会信用码
          this.formTable.SocialUnifiedCredit = res.data.data.creditCode;
          this.formTable.cisCode = res.data.data.cisCode;
          this.formTable.MDMCode = res.data.data.MDMCode;
          this.formTable.MarketLevel = String(res.data.data.marketLevelId);
          this.formTable.email = res.data.data.postalCode;
          this.formTable.channelLevel = String(res.data.data.primaryChannelId);
          this.formTable.processInstanceId= res.data.data.processInstId
          this.formTable.bdId = res.data.data.bdId ? res.data.data.bdId + "" : "";
          this.getBUList(this.formTable.bdId);
          this.formTable.bu1Id = res.data.data.bu1Id ? res.data.data.bu1Id + "" : "";
          if (this.formTable.channelLevel == 5611) {
            this.$nextTick(() => {
              this.checkRule = true;
              this.rules.cooperativeNatureId[0].required = true;
              this.rules.householdNatureId[0].required = true;
              this.getCooperativeNature();
              this.gethouseholdNature();
              this.$forceUpdate();
              this.formTable.cooperativeNatureId = String(
                res.data.data.cooperationNatureId
              );
              this.formTable.householdNatureId = String(
                res.data.data.householdNatureId
              );
            });
          } else {
            this.checkRule = false;
            this.rules.cooperativeNatureId[0].required = false;
            this.rules.householdNatureId[0].required = false;
            this.formTable.cooperativeNatureId = "";
            this.formTable.householdNatureId = "";
            this.$forceUpdate();
          }
          this.getDMSxf(this.formTable.channelLevel);
          this.formTable.channelLevelSegmentation = String(
            res.data.data.secondChannelId
          );
          this.formTable.groupLevelLabel = String(res.data.data.primaryLabelId);
          this.getgroupSecondaryLabel(this.formTable.groupLevelLabel);
          this.formTable.groupSecondaryLabel = String(
            res.data.data.secondLabelId
          );
          // 集团三级标签
          this.getThreeLabel(this.formTable.groupSecondaryLabel);
          this.formTable.thirdLabelId = res.data.data.thirdLabelId
            ? String(res.data.data.thirdLabelId)
            : "";
          this.formTable.Region = String(res.data.data.largeAreaText);
          this.formTable.RegionId = String(res.data.data.largeAreaId);
          this.formTable.cityLevel = String(res.data.data.districtType);
          this.formTable.MerchantCategory = res.data.data.custCategory;
          this.formTable.NetworkType = String(res.data.data.networkTypeId);

          // this.formTable.annualContractAmount = res.data.data.annualContractAmount;
          //税务登记证/营业执照/身份证
          this.formTable.IdentificationNumber = res.data.data.taxCode;
          this.formTable.businessLicense = res.data.data.businessLicense;
          this.formTable.idCard = res.data.data.idCard;
          this.formTable.province = String(res.data.data.provinceId);

          this.getCityList(this.formTable.province);
          this.formTable.city = res.data.data.cityId
            ? String(res.data.data.cityId)
            : "";
          this.getaREAList(this.formTable.city);
          this.formTable.area = res.data.data.countyId
            ? String(res.data.data.countyId)
            : "";
          this.getTownList(this.formTable.area);
          this.formTable.Township = res.data.data.townsId
            ? String(res.data.data.townsId)
            : "";
          this.formTable.Telephone = res.data.data.telephone;
          this.formTable.bossPhone = res.data.data.bossTel;
          this.formTable.corporateStockholder = res.data.data.holderPeople;
          this.formTable.keyPersonnel = res.data.data.majorPeople;
          this.formTable.officeAddress = res.data.data.officeAddress;
          this.formTable.companyRegisteredAddress = res.data.data.address;
          this.formTable.legalPerson = res.data.data.legalPerson;
          if (res.data.data.bankName) {
            this.getbanktList1(res.data.data.bankName);
          }
          this.formTable.bankName = res.data.data.bankName;
          this.formTable.accountHolder = res.data.data.accountHolder;
          this.formTable.bankAccount = res.data.data.bankAccount;
          this.formTable.taxClassification = String(res.data.data.taxTypeId);
          this.formTable.accountGroup = res.data.data.agrpText;
          this.formTable.accountGroupId = res.data.data.agrpId;
          //   三证合一附件
          if (res.data.data.allCertAttach && res.data.data.allCertAttach.id) {
            this.appendixFileList.push({
              uid: res.data.data.allCertAttach.id,
              name: res.data.data.allCertAttach.attachName,
              url: res.data.data.allCertAttach.attachPath,
              name2: "旧的",
            });
            // this.aaa = []
            // this.aa.push()
            // res.data.allCertAttach.length > 0
            // res.data.allCertAttach.forEach(item => {
            //   this.xxx.push({
            //     MerchantManagerContactPhone: item.phone,

            //    })
            //  })

            // 三证合一id
            this.appendixFileId.id = res.data.data.allCertAttach.id;
          }
          // 营业执照
          if (res.data.data.licAttach && res.data.data.licAttach.id) {
            this.businessLicense.push({
              uid: res.data.data.licAttach.id,
              name: res.data.data.licAttach.attachName,
              url: res.data.data.licAttach.attachPath,
              name2: "旧的",
            });
            // 营业执照id
            this.businessLicenseId.id = res.data.data.licAttach.id;
          }
          //税务
          if (res.data.data.taxAttach && res.data.data.taxAttach.id) {
            this.taxFileList.push({
              uid: res.data.data.taxAttach.id,
              name: res.data.data.taxAttach.attachName,
              url: res.data.data.taxAttach.attachPath,
              name2: "旧的",
            });
            // 税务id
            this.taxFileId.id = res.data.data.taxAttach.id;
          }

          //组织结构
          if (res.data.data.orgAttach && res.data.data.orgAttach.id) {
            this.mechanismFileList.push({
              uid: res.data.data.orgAttach.id,
              name: res.data.data.orgAttach.attachName,
              url: res.data.data.orgAttach.attachPath,
              name2: "旧的",
            });
            // 组织结构id
            this.mechanismFileId.id = res.data.data.orgAttach.id;
          }

          //其他
          if (res.data.data.modAttachList.length > 0) {
            res.data.data.modAttachList.forEach((item) => {
              this.otherFileList.push({
                uid: item.id,
                name: item.attachName,
                url: item.attachPath,
                name2: "旧的",
              });
              // 其他id
              this.otherFileId.push({
                id: item.id,
              });
            });
          }

          // 查询工商信息
          let dataI = {
            companyName: this.formTable.shopName,
          };
          findCompanyBaseInfo(dataI).then((res) => {
            if (res.data.code == "0" && res.data.data.result) {
              this.enterPriseData = res.data.data.result;
              // 工商注册号
              this.formTable.RegistrationNumber = this.enterPriseData.staffInfo.regNumber;
              // 企业类型
              this.formTable.EnterpriseType = this.enterPriseData.staffInfo.companyOrgType;
              //企业状态
              this.formTable.qyStatus = this.enterPriseData.staffInfo.regStatus;
            } else {
              this.$message.warning(res.data.msg);
            }
          });

          this.spinning = false;
        } else {
          this.$message.warning("查询详情失败");
          this.spinning = false;
        }
      });
    },
    // 获取贸易关系详情
    async getMyTable(id) {
      let data = {
        customerInfoId: id,
      };
      findTradeRelationDetail(data).then((res) => {
        var maoyidata = res.data.data;
        if (res.data && res.data.data.length>0 && res.data.data[0].materialGroupOrgName) {
          maoyidata.forEach((item) => {
            this.newtableData.push({
              id: item.id,
              customerMarketModelId:item.customerMarketModelId,
              MaterialGroup: item.materialGroupOrgName,
              MerchantManagerContact: item.customerManager,
              MerchantManagerContactPhone: item.customerManagerTel,
              MaterialGroupcode:item.materialGroupOrgId
            });
          });
        }
        res.data.data.forEach(async (item) => {
          item.materialList = [];
          item.CooperationTypeList = [];
          let res = await this.getMaterialGroup(item.orgId);
          let resHZ = await this.getHZMs(item.matklId, item.orgId);
          item.CooperationTypeList = resHZ.data.list;
          item.materialList = res.data.list;
          this.tableData.push({
            branchId: String(item.orgId),
            branchName: item.orgName,
            materialList: item.materialList,
            materialId: String(item.matklId),
            MarketingTypeId: String(item.marketModelId),
            agentId: item.superiorAgentId,
            id:item.id,
            CooperationTypeList: item.CooperationTypeList,
            CooperationId: String(item.cooperationModelId),
            businessId: String(item.ywTypeId),
            concat: item.customerManager,
            concatPhone: item.customerManagerTel,
            CooperationStatusId: String(item.djFlag),
            CooperationStatusName: item.djFlagText,
            freezeMarkId: "1",
            freezeMarkName: "合作",
            freezeTime: item.djTime,
          });
          
        });
      });
    },
    // 获取销售
    async getSaleInfo(id) {
      let data = {
        customerInfoId: id,
      };
      findSalesViewDetail(data).then((res) => {
        res.data.data.forEach(async (item) => {
          item.SaleAddressList = [];
          item.CooperationTypeList = [];
          let res = await this.getSaleAdress1(item.orgId);
          item.SaleAddressList = res.data.list;
          this.saleTableData.push({
            orgId: String(item.orgId),
            orgName: item.orgName,
            SaleAddressList: item.SaleAddressList,
            saleAddressId: String(item.salesAreaId),
            priceId: String(item.priceGroupId),
            payId: String(item.paymentTermsId),
            saleFreezeId: String(item.djFlag),
          });
        });
      });
    },

    // 获取门店信息
    async getCompanyInfo(id) {
      let data = {
        customerInfoId: id,
      };
      findCompanyViewDetail(data).then((res) => {
        res.data.data.forEach(async (item) => {
          this.companyTableData.push({
            companyId: String(item.orgId),
            companyName: item.orgName,
            SubjectId: String(item.reconciliationAccountId),
            RulesId: String(item.selectionRoleId),
            TermPaymenId: String(item.paymentTermsId),
            remark: item.accountNote,
            BookkeepingFreezeId: String(item.djFlag),
          });
        });
      });
    },
    // 银行账户详情
    getBankInfo(id) {
      let data = {
        customerInfoId: id,
      };
      findBankAccountDetail(data).then((res) => {
        res.data.data.forEach(async (item) => {
          let res = await this.getbanktListInfo(item.custBankName);
          item.bankList = res.data.list;
          this.BankTableData.push({
            id:item.id,
            bankList: item.bankList,
            bankId: String(item.custBankId),
            bankName: item.custBankName,
            bankNumber: item.interBankNumber,
            bankAccount: item.custBankAccount,
            accountHolder: item.custBankHolder,
            billingAccountId: String(item.isOpenBank),
          });
        });
      });
    },
    // 银行
    getbanktListInfo(value) {
      let data = {
        searchKeywords: value,
      };
      // findBankInfoCombobox(data).then(res => {
      //   let data;
      //   if (typeof res.data == "string") {
      //     data = JSON.parse(res.data);
      //     this.selctRow.fetching = false;
      //   } else {
      //     data = res.data;
      //   }
      //   this.selctRow.fetching = false;
      //   this.$forceUpdate();
      //   bankList = [...new Set(data.list)]; //缓存列表数据，前端分页
      // });
      return findBankInfoCombobox(data);
    },
    // 编辑销售信息 查询销售地址
    getSaleAdress1(e) {
      let data = {
        orgId: e,
      };
      return findSalesAreaCombobox(data);
    },
    getMaterialGroup(e) {
      let data = {
        branchCompanyId: e,
      };
      return findMaterialGroupCombobox(data);
    },

    changeDictionary(e) {
      // console.log("e", e);
    },
    shopNameBlur() {
      if (!this.formTable.shopName) {
        this.shopNameShow = true;
      } else {
        this.shopNameShow = false;
      }
    },
    jcNameBlur() {
      if (!this.formTable.jcName) {
        this.jcNameShow = true;
      } else {
        this.jcNameShow = false;
      }
    },
    //获取cisCode
    // getCisCode() {
    //   let data = {};
    //   if (!this.isbianji) {
    //     getCiseCode(data).then((res) => {
    //       console.log(res);
    //     if (res.data.code == 0) {
    //       this.formTable.cisCode = res.data.msg;
    //     } else {
    //       this.formTable.cisCode = "";
    //     }
    //   });
    //   }
    // },
    // 市场级别
    getMarkctType() {
      let data = {
        pid: 17200,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.MarkctTypeList = res.data.list;
        }
      });
    },
    // 查询渠道类别
    getDmsType() {
      let data = {
        pid: 5551,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.DmsTypeList = res.data.list;
        }
      });
    },

    changeDMStype(e) {
      this.getDMSxf(e);
      this.formTable.channelLevelSegmentation = "";
      if (e == 5611) {
        this.$nextTick(() => {
          this.checkRule = true;
          this.rules.cooperativeNatureId[0].required = true;
          this.rules.householdNatureId[0].required = true;
        });
        this.$forceUpdate();

        this.getCooperativeNature();
        this.gethouseholdNature();
      } else {
        this.checkRule = false;
        this.rules.cooperativeNatureId[0].required = false;
        this.rules.householdNatureId[0].required = false;
        this.formTable.cooperativeNatureId = "";
        this.formTable.householdNatureId = "";
      }
    },
    changeCooperativeNatureId(e) {
      this.formTable.cooperativeNatureId = e;
      this.$forceUpdate();
    },
    changeHouseholdNatureId(e) {
      this.formTable.householdNatureId = e;
      this.$forceUpdate();
    },
    //渠道类别细分下拉框
    getDMSxf(e) {
      let data = {
        primaryChannelCategoryId: e,
      };
      findSubChannelCategoryCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.DMSxfList = res.data.list;
        }
      });
    },
    // 获取BD列表
    getBDList() {
      getBDList().then((res) => {
        if (res.data.code == 0) {
          this.BDList = res.data.list;
        }
      });
    },
    changeBD(e) {
      this.getBUList(e);
      this.formTable.bu1Id = "";
    },
    // 获取BU列表
    getBUList(id){
      getBUList(id).then((res) => {
        if (res.data.code == 0) {
          this.BUList = res.data.list;
        }
      });
    },
    //集团一级标签
    getgroupLevelLabel() {
      let data = {
        pid: 14169732977,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.groupLevelList = res.data.list;
        }
      });
    },
    // 修改集团一级数据
    changeGroupLevelLabel(e) {
      // 更改集团一级标签查询集团二级标签
      this.getgroupSecondaryLabel(e);
      this.formTable.groupSecondaryLabel = "";
    },
    //获取集团二级标签
    getgroupSecondaryLabel(id) {
      let data = {
        groupPrimaryLabelId: id,
      };
      groupSecondaryLabel(data).then((res) => {
        if (res.data.code == 0) {
          this.groupSecondList = res.data.list;
        }
      });
    },
    // 更改二级标签
    changeTwoGroupLevelLabel(e) {
      // 更改集团一级标签查询集团二级标签
      this.getThreeLabel(e);
      this.formTable.thirdLabelId = "";
    },
    //获取集团三级标签
    getThreeLabel(id) {
      let data = {
        secondLabelId: id,
      };
      getThirdLabelBySecondLabel(data).then((res) => {
        if (res.data.code == 0) {
          this.groupThreeList = res.data.list;
        }
      });
    },

    // 获取城市级别下拉
    getCityType() {
      findCityLevelComboboxx({}).then((res) => {
        if (res.data.code == 0) {
          this.CityTypeList = res.data.list;
        }
      });
    },
    // 获取商家类别
    getDictionaryCom() {
      let data = {
        categoryCode: "custCategory",
      };
      findDictionaryCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.DictionaryList = res.data.list;
        }
      });
    },
    // 网点类型
    getNetworkType() {
      let data = {
        pid: 14183485334,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.NetworkTypeList = res.data.list;
        }
      });
    },
    // 省
    getShengList() {
      let data = {
        parentRegionId: "",
      };
      findAdministrativeCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.shengList = res.data.list;
        }
      });
    },

    changeSheng(e) {
      this.cityList = [];
      this.areaList = [];
      this.TownList = [];
      this.formTable.city = "";
      this.formTable.area = "";
      this.formTable.Township = "";
      this.getCityList(e);
      // //   查询所属大区
      findRegionByProvinceId({ provinceId: e }).then((res) => {
        this.formTable.Region = res.data.data.name;
        this.formTable.RegionId = res.data.data.code;
      });
    },
    getCityList(e) {
      let data = {
        parentRegionId: e,
      };
      findAdministrativeCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.cityList = res.data.list;
        }
      });
    },
    changeCity(e) {
      this.areaList = [];
      this.TownList = [];
      this.formTable.Township = "";
      this.formTable.area = "";
      this.getaREAList(e);
    },
    // 查询区
    getaREAList(e) {
      let data = {
        parentRegionId: e,
      };
      findAdministrativeCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.areaList = res.data.list;
        }
      });
    },
    // 修改区
    changeAreaList(e) {
      this.TownList = [];
      this.formTable.Township = "";
      this.getTownList(e);
    },
    // 查询xiangzhen
    getTownList(e) {
      let data = {
        parentRegionId: e,
      };
      findAdministrativeCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.TownList = res.data.list;
        }
      });
    },
    // 税分类
    getshuiList() {
      let data = {
        pid: 7458453,
      };
      focusonremarkplz(data).then((res) => {
        if (res.data.code == 0) {
          this.shuiList = res.data.list;
        }
      });
    },
    // 合作的分销商
    getFXList() {
      let data = {};
      findBranchCompanyCombobox(data).then((res) => {});
    },
    // 统御科目

    openWindow() {
      if (!this.formTable.shopName) {
        this.$message.warning("请先填写商家全称");
        return;
      }
      this.Bloading = true;
      this.spinningInfo = true;
      let data = {
        companyName: this.formTable.shopName,
      };
      findCompanyBaseInfo(data).then((res) => {
        this.spinningInfo = false;
        this.Bloading = false;
        if (res.data.code == "0" && res.data.data.result) {
          this.enterPriseData = res.data.data.result;
          this.enterPriseData.type =
            res.data.data.result.type == "1" ? "个人" : "公司";
          this.enterPriseData.isMicroEnt =
            res.data.data.result.isMicroEnt == "1" ? "是" : "否";
          this.visible = true;
          this.spinningInfo = false;
        } else {
          this.$message.warning(res.data.msg);
          this.Bloading = false;
          this.spinningInfo = false;
        }
      });
    },

    handleOk() {
      if (this.enterPriseData && this.enterPriseData.staffInfo) {
        this.formTable.IdentificationNumber = this.enterPriseData.staffInfo.taxCode;
        this.formTable.RegistrationNumber = this.enterPriseData.staffInfo.regNumber;
        this.formTable.SocialUnifiedCredit = this.enterPriseData.staffInfo.creditCode;
        this.formTable.EnterpriseType = this.enterPriseData.staffInfo.companyOrgType;
        this.formTable.qyStatus = this.enterPriseData.staffInfo.regStatus;
        this.formTable.companyRegisteredAddress = this.enterPriseData.regLocation;
        this.formTable.legalPerson = this.enterPriseData.legalPersonName;
        this.formTable.keyPersonnel = this.enterPriseData.staffInfo.majorPeople;
        this.formTable.corporateStockholder = this.enterPriseData.staffInfo.holderPeople;
      }

      this.visible = false;
      // this.newtableData = []; 
    },
    handleCancel() {
      this.visible = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 删除贸易关系数据
    delLine(type) {
      if (type == "tradeRelations") {
        if (this.selectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.tableData = this.tableData.filter(
            (item, index) => !this.selectedRowKeys.includes(index)
          );
          this.selectedRowKeys = [];
        }
      }
      if (type == "sale") {
        if (this.selectedRowKeysSale.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.saleTableData = this.saleTableData.filter(
            (item, index) => !this.selectedRowKeysSale.includes(index)
          );
          this.selectedRowKeysSale = [];
        }
      }
      if (type == "company") {
        if (this.selectedRowKeysCompany.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.companyTableData = this.companyTableData.filter(
            (item, index) => !this.selectedRowKeysCompany.includes(index)
          );
          this.selectedRowKeysCompany = [];
        }
      }
      if (type == "bank") {
        if (this.selectedRowKeysBank.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.BankTableData = this.BankTableData.filter(
            (item, index) => !this.selectedRowKeysBank.includes(index)
          );
          this.selectedRowKeysBank = [];
        }
      }
      if (type == "HRM") {
        if (this.selectedRowKeysHRM.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.HRMTableData = this.HRMTableData.filter(
            (item, index) => !this.selectedRowKeysHRM.includes(index)
          );
          this.selectedRowKeysHRM = [];
        }
      }
    },
    // 地址信息
    onAddressChange(addressValArr) {
      this.formTable.province = addressValArr[0];
      this.formTable.city = addressValArr[1];
      this.formTable.area = addressValArr[2];
      this.formTable.Township = addressValArr[3];
    },
    //表单验证
    maoyionSubmit() {
      this.$refs.maoyiruleForm.validate((valid) => {
        if (valid) {
        } else {
          this.$message.warning("请填写正确信息");
          return false;
        }
      });
    },

    //添加贸易数据
    async IncreaseTradeData() {
      if (
        this.MaterialGroup == "" ||
        this.MerchantManagerContact == "" ||
        this.MerchantManagerContactPhone == ""
      ) {
        this.$message.warning("请补全贸易关系中的内容");
        return false;
      }
      //手机号验证
      var phoneReg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!phoneReg.test(this.MerchantManagerContactPhone)) {
        this.$message.warning("请输入正确的商家经理手机号");
        return;
      }

      //查看所选物料组跟已经添加物料组是否一样
      let p = this.newtableData.some((item) => {
        return item.MaterialGroup == this.MaterialGroup;
      });
      //得到所选物料组在全部的物料组中的code
      this.MaterialGroupcode = this.getallMaterialGroup[0][
        this.getallMaterialGroup[0].findIndex((item) => {
          return item.name == this.MaterialGroup;
        })
      ].code;
      //  得到分公司id
      this.branchOfficeid = this.MaterialGroupcode.slice(12);
      
      //点击编辑之后再添加

      if (this.isEdit) {
        var id=this.tableData[this.EditIndex]?.id
        this.newtableData.splice(this.EditIndex, 1, {
          id:id,
          MaterialGroup: this.MaterialGroup,
          MaterialGroupcode: this.MaterialGroupcode,
          BranchOfficeid: this.branchOfficeid,
          MerchantManagerContact: this.MerchantManagerContact,
          MerchantManagerContactPhone: this.MerchantManagerContactPhone,
        });
        this.MaterialGroup = "";
        this.MaterialGroupcode = "";
        this.branchOfficeid = "";
        this.MerchantManagerContact = "";
        this.MerchantManagerContactPhone = "";
        this.isEdit = false;
        this.addmessage = false;
      } else {
        if (p) {
          this.$message.warning("该物料组-分公司已维护！");
        } else {
          this.newtableData.push({
            MaterialGroup: this.MaterialGroup,
            BranchOfficeid: this.branchOfficeid,
            MerchantManagerContact: this.MerchantManagerContact,
            MaterialGroupcode: this.MaterialGroupcode,
            MerchantManagerContactPhone: this.MerchantManagerContactPhone,
          });
          this.MaterialGroup = "";
          this.MaterialGroupcode = "";
          this.branchOfficeid = "";
          this.MerchantManagerContact = "";
          this.MerchantManagerContactPhone = "";
        }
      }
      this.addmessage = false;
    },
    // 编辑贸易信息
    TradeEditor(index) {
      this.isEdit = true;
      this.addmessage = true;
      this.MaterialGroup = this.newtableData[index].MaterialGroup;
      this.MerchantManagerContact = this.newtableData[
        index
      ].MerchantManagerContact;
      this.MerchantManagerContactPhone = this.newtableData[
        index
      ].MerchantManagerContactPhone;
      this.customerMarketModelId= this.newtableData[
        index
        ].customerMarketModelId?this.newtableData[
        index
        ].customerMarketModelId:'';
      this.EditIndex = index;
    },
    // 删除贸易信息--新

    showConfirm(index) {
      this.isLoginModalShow = true;
      this.giveindex = index;
    },
    userLayout() {
      var index = this.giveindex;
      this.newtableData.splice(index, 1);
    },

    // 点击取消隐藏贸易信息
    deletemessage() {
      this.addmessage = false;
      this.MaterialGroup = ''
      this.MerchantManagerContact = ''
      this.MerchantManagerContactPhone = ''
      this.customerMarketModelId = ''
      this.EditIndex=''
      this.isEdit = false
    },
    addline(type) {
      // 贸易关系
      if (type == "tradeRelations") {
        this.addmessage = true;
        this.customerMarketModelId = ''
        this.MerchantManagerContactPhone = ''
        this.MerchantManagerContact = ''
        this.MaterialGroup = ''
        this.tableData.push({
          freezeMarkId: "1",
          freezeMarkName: "合作",
          agentId: this.$store.state.user.userInfo.customer.id,
          agentName: this.$store.state.user.userInfo.customer.customerName,
          MarketingTypeId: "17451",
          MarketingTypeName: "分销",
          CooperationStatusId: "1",
          CooperationStatusName: "合作",
        });
      }
    },




    handleDownload(file) {
      window.open(file.url, "_blank");
    },
    // 营业执照
    beforeUpload1(file) {
      this.pageLoadFlag = true;
      const _this = this; // 文件大小不能超过十兆
      const size = file.size / 1024 / 1024 < 5;
      return new Promise((resolve, reject) => {
        if (!size) {
          this.$message.warning("文件大小不能超过5M！");
          this.pageLoadFlag = false;
          reject(file);
        } else {
          if (size) {
            const formData = new FormData();
            formData.append("file1", file);
            formData.append("fileType", "addshop");
            // 上传接口/custAgentActivity/uploadFile.nd
            let config = {
              headers: { "content-type": "multipart/form-data" },
            };
            // 发送请求
            const BASE_API = publicPath;
            axios
              .post(BASE_API + "/comm/method=uploadFile.nd", formData, config)
              .then((res) => {
                if (res.data.message == "上传成功") {
                  // this.businessLicenseId = [];
                  this.businessLicenseId.id = "";
                  // this.businessLicenseId = res.data.businessId // 字符串形式
                  // this.businessLicenseId.push({ // 数组形式
                  //   id: res.data.businessId
                  // });
                  this.businessLicenseId.id = res.data.businessId; // 对象形式
                  this.businessLicense = [..._this.businessLicense, file];
                  _this.businessLicense = _this.businessLicense.slice(-1);
                  this.businessLicense.forEach((item) => {
                    item.size2 = (item.size / 1024).toFixed(2);
                  });
                  this.pageLoadFlag = false;
                } else {
                  this.$message.warning("上传失败," + res.data.message);
                  this.pageLoadFlag = false;
                }
              });
            return false;
          }
        }
      });
    },
    handleChange1({ fileList }) {
      let fileList1 = fileList;
      this.businessLicense = fileList1.slice(-1);
      this.businessLicense.forEach((item) => {
        item.size2 = (item.size / 1024).toFixed(2);
      });
    },
    handleRemove1(file) {
      const index = this.businessLicense.indexOf(file);
      const newFileList = this.businessLicense.slice();
      newFileList.splice(index, 1);
      this.businessLicense = newFileList;
      // this.businessLicenseId.splice(index, 1);
      this.businessLicenseId.id = "";
    },

    //三证合一
    beforeUpload2(file) {
      this.pageLoadFlag = true;
      const _this = this; // 文件大小不能超过十兆
      const size = file.size / 1024 / 1024 < 5;
      return new Promise((resolve, reject) => {
        if (!size) {
          this.$message.warning("文件大小不能超过5M！");
          this.pageLoadFlag = false;
          reject(file);
        } else {
          if (size) {
            const formData = new FormData();
            formData.append("file1", file);
            formData.append("fileType", "addshop");
            // 上传接口/custAgentActivity/uploadFile.nd
            let config = {
              headers: { "content-type": "multipart/form-data" },
            };
            // 发送请求
            const BASE_API = publicPath;
            axios
              .post(BASE_API + "/comm/method=uploadFile.nd", formData, config)
              .then((res) => {
                if (res.data.message == "上传成功") {
                  this.appendixFileId.id = "";
                  // this.appendixFileId = res.data.businessId
                  this.appendixFileId.id = res.data.businessId;
                  this.appendixFileList = [..._this.appendixFileList, file];
                  _this.appendixFileList = _this.appendixFileList.slice(-1);
                  this.appendixFileList.forEach((item) => {
                    item.size2 = (item.size / 1024).toFixed(2);
                  });
                  this.pageLoadFlag = false;
                } else {
                  this.$message.warning("上传失败," + res.data.message);
                  this.pageLoadFlag = false;
                }
              });
            return false;
          }
        }
      });
    },
    handleChange2({ fileList }) {
      let fileList1 = fileList;
      this.appendixFileList = fileList1.slice(-1);
      this.appendixFileList.forEach((item) => {
        item.size2 = (item.size / 1024).toFixed(2);
      });
    },
    handleRemove2(file) {
      const index = this.appendixFileList.indexOf(file);
      const newFileList = this.appendixFileList.slice();
      newFileList.splice(index, 1);
      this.appendixFileList = newFileList;
      // this.appendixFileId.splice(index, 1);
      this.appendixFileId.id = "";
    },

    // 税务登记证
    beforeUpload3(file) {
      this.pageLoadFlag = true;
      const _this = this; // 文件大小不能超过十兆
      const size = file.size / 1024 / 1024 < 5;
      return new Promise((resolve, reject) => {
        if (!size) {
          this.$message.warning("文件大小不能超过5M！");
          this.pageLoadFlag = false;
          reject(file);
        } else {
          if (size) {
            const formData = new FormData();
            formData.append("file1", file);
            formData.append("fileType", "addshop");
            // 上传接口/custAgentActivity/uploadFile.nd
            let config = {
              headers: { "content-type": "multipart/form-data" },
            };
            // 发送请求
            const BASE_API = publicPath;
            axios
              .post(BASE_API + "/comm/method=uploadFile.nd", formData, config)
              .then((res) => {
                if (res.data.message == "上传成功") {
                  this.taxFileId.id = "";
                  // this.taxFileId = res.data.businessId
                  this.taxFileId.id = res.data.businessId;
                  this.taxFileList = [..._this.taxFileList, file];
                  _this.taxFileList = _this.taxFileList.slice(-1);
                  this.taxFileList.forEach((item) => {
                    item.size2 = (item.size / 1024).toFixed(2);
                  });
                  this.pageLoadFlag = false;
                } else {
                  this.$message.warning("上传失败," + res.data.message);
                  this.pageLoadFlag = false;
                }
              });
            return false;
          }
        }
      });
    },
    handleChange3({ fileList }) {
      let fileList1 = fileList;
      this.taxFileList = fileList1.slice(-1);
      this.taxFileList.forEach((item) => {
        item.size2 = (item.size / 1024).toFixed(2);
      });
    },
    handleRemove3(file) {
      const index = this.taxFileList.indexOf(file);
      const newFileList = this.taxFileList.slice();
      newFileList.splice(index, 1);
      this.taxFileList = newFileList;
      // this.taxFileId.splice(index, 1);
      // this.taxFileId = "";
      this.taxFileId.id = "";
    },

    // 其他附件
    beforeUpload4(file) {
      this.pageLoadFlag = true;
      const _this = this; // 文件大小不能超过十兆
      const size = file.size / 1024 / 1024 < 5;
      return new Promise((resolve, reject) => {
        if (!size) {
          this.$message.warning("文件大小不能超过5M！");
          this.pageLoadFlag = false;
          reject(file);
        } else {
          if (size) {
            const formData = new FormData();
            formData.append("file1", file);
            formData.append("fileType", "addshop");
            // 上传接口/custAgentActivity/uploadFile.nd
            let config = {
              headers: { "content-type": "multipart/form-data" },
            };
            // 发送请求
            const BASE_API = publicPath;
            axios
              .post(BASE_API + "/comm/method=uploadFile.nd", formData, config)
              .then((res) => {
                if (res.data.message == "上传成功") {
                  this.otherFileId.push({
                    id: res.data.businessId,
                  });
                  this.otherFileList = [..._this.otherFileList, file];
                  this.otherFileList.forEach((item) => {
                    item.size2 = (item.size / 1024).toFixed(2);
                  });
                  // 如果是信天翁发起 只能上传一个 如果是cis 可以穿多个
                  // if (this.$route.query.dataSorce !== "cis") {
                  //   this.otherFileId = [];
                  //   // this.otherFileId = res.data.businessId
                  //   this.otherFileId.push({
                  //     id: res.data.businessId
                  //   });
                  //   this.otherFileList = [..._this.otherFileList, file];
                  //   _this.otherFileList = _this.otherFileList.slice(-1);
                  //   this.otherFileList.forEach(item => {
                  //     item.size2 = ((item.size) / 1024).toFixed(2);
                  //   });
                  // } else {
                  //
                  // }

                  this.pageLoadFlag = false;
                  this.onlyone=true
                } else {
                  this.$message.warning("上传失败," + res.data.message);
                  this.pageLoadFlag = false;
                }
              });
            return false;
          }
        }
      });
    },
    handleChange4({ fileList }) {
      let fileList1 = fileList;
      this.otherFileList = fileList1.slice(-1);
      this.otherFileList.forEach((item) => {
        item.size2 = (item.size / 1024).toFixed(2);
      });
      
    },
    handleRemove4(file) {
      const index = file;
      const newFileList = this.otherFileList.slice();
      newFileList.splice(index, 1);
      this.otherFileList = newFileList;
      this.otherFileId.splice(index, 1);
      this.onlyone=false
    },

    // 组中机构代码证
    beforeUpload5(file) {
      this.pageLoadFlag = true;
      const _this = this; // 文件大小不能超过十兆
      const size = file.size / 1024 / 1024 < 5;
      return new Promise((resolve, reject) => {
        if (!size) {
          this.$message.warning("文件大小不能超过5M！");
          this.pageLoadFlag = false;
          reject(file);
        } else {
          if (size) {
            const formData = new FormData();
            formData.append("file1", file);
            formData.append("fileType", "addshop");
            // 上传接口/custAgentActivity/uploadFile.nd
            let config = {
              headers: { "content-type": "multipart/form-data" },
            };
            // 发送请求
            const BASE_API = publicPath;
            axios
              .post(BASE_API + "/comm/method=uploadFile.nd", formData, config)
              .then((res) => {
                if (res.data.message == "上传成功") {
                  // this.mechanismFileId = [];
                  this.mechanismFileId.id = res.data.businessId;
                  // this.mechanismFileId = res.data.businessId
                  // this.mechanismFileId.push({
                  //   id: res.data.businessId
                  // });
                  this.mechanismFileList = [..._this.mechanismFileList, file];
                  _this.mechanismFileList = _this.mechanismFileList.slice(-1);
                  this.mechanismFileList.forEach((item) => {
                    item.size2 = (item.size / 1024).toFixed(2);
                  });
                  this.pageLoadFlag = false;
                } else {
                  this.$message.warning("上传失败," + res.data.message);
                  this.pageLoadFlag = false;
                }
              });
            return false;
          }
        }
      });
    },
    handleChange5({ fileList }) {
      let fileList1 = fileList;
      this.mechanismFileList = fileList1.slice(-1);
      this.mechanismFileList.forEach((item) => {
        item.size2 = (item.size / 1024).toFixed(2);
      });
    },
    handleRemove5(file) {
      const index = this.mechanismFileList.indexOf(file);
      const newFileList = this.mechanismFileList.slice();
      newFileList.splice(index, 1);
      this.mechanismFileList = newFileList;
      // this.mechanismFileId.splice(index, 1);
      // this.mechanismFileId = "";
      this.mechanismFileId.id = "";
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //左侧导航点击
    navClickFun(index, isScroll) {
      for (const idx in this.menuList) {
        if (idx == index) {
          if (!isScroll) {
            $("body,html").animate(
              {
                // scrollTop: $("#type" + index).offset().top - 80,
                scrollTop: $("#type" + index).offset().top,
              },
              // 360
              0
            );
          }
          //活动状态
          let timer = setTimeout(() => {
            for (const idx in this.menuList) {
              this.menuList[idx].active = false;
            }
            this.menuList[index].active = true;
            clearTimeout(timer);
          }, 1);
          return;
        }
      }
    },

    //左侧导航跟随滚动
    navScrollFun(index, isScroll) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      for (const idx in this.menuList) {
        if (idx == index) {
          this.menuList[index].active = true;
          return;
        }
      }
    },
    //判断详情通栏是否吸附
    handleScroll() {
      if (!this.scrollWatch) {
        return;
      }
      if ($("#boxFixedRefer").offset() && $("#boxFixedRefer").offset().top) {
        let scrollTop =
          $("#boxFixedRefer").offset().top - $(window).scrollTop();
        if (scrollTop <= 0) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }
      //滚动-》左侧交互
      // if ($("#type8").offset().top - $(window).scrollTop() < 100) {
      //   this.navScrollFun(8, true);
      // } else
      // if ($("#type7").offset().top - $(window).scrollTop() < 60) {
      //   this.navScrollFun(7, true);
      // } else if ($("#type6").offset().top - $(window).scrollTop() < 60) {
      //   this.navScrollFun(6, true);
      // } else
      // if ($("#type5").offset().top - $(window).scrollTop() < 60) {
      //   this.navScrollFun(5, true);
      // } else
      if ($("#type4").offset().top - $(window).scrollTop() < 60) {
        this.navScrollFun(4, true);
      } else if ($("#type3").offset().top - $(window).scrollTop() < 60) {
        this.navScrollFun(3, true);
      } else if ($("#type2").offset().top - $(window).scrollTop() < 60) {
        this.navScrollFun(2, true);
      } else if ($("#type1").offset().top - $(window).scrollTop() < 60) {
        this.navScrollFun(1, true);
      } else if ($("#type0").offset().top - $(window).scrollTop() < 60) {
        this.navScrollFun(0, true);
      }
    },

    //左侧点击交互 暂时不用
    navJumpFun(index) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      this.menuList[index].active = true;
    },

    // 返回
    goback() {
      delStoreUser();
      delStoreUser1();
      if(this.$route.query.url === 'jurisdictionalMerchants') {
        this.$router.push({
          path: "/jurisdiction/merchants",
        });
        return
      }else{
        this.$router.push({
          path: "/basicInfo?type=3",
        });
      }


    },
    // 上级代理模糊搜索
    searchbank1() {},
    // 模糊搜索上级代理
    getbanktList1(value) {
      this.bankSearchStr1 = value;

      if (this.bankSearchStr1.length >= 2) {
        let data = {
          searchKeywords: this.bankSearchStr1,
        };

        this.fetching = true;
        this.$forceUpdate();
        findBankInfoCombobox(data).then((res) => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
            this.fetching = false;
          } else {
            data = res.data;
          }
          this.fetching = false;
          this.$forceUpdate();
          this.bankList1 = [...new Set(data.list)]; //缓存列表数据，前端分页
        });
      } else {
        if (!this.$route.query.id) {
          this.$message.warning("至少输入两个文字进行搜索");
        }
        return;
      }
    },
    //更改上级代理
    changebank1(value) {
      this.bankSearchStr1 = value;
      this.formTable.bankId = value;
      //  调用
      let obj = this.bankList1.find((i) => {
        //listData就是上面的数据源
        return i.code === value; //筛选出匹配数据
      });
      this.formTable.bankName = obj.name;
      this.$forceUpdate();
    },
    isContentEmpty(tagContent) {
      // 使用DOMParser来解析字符串为DOM元素
      var parser = new DOMParser();
      var doc = parser.parseFromString(tagContent, 'text/html');
      // 获取解析后的元素的纯文本内容（不包含标签）
      var textContent = doc.body.textContent || "";
      return textContent
    },
    //下一步
    saveSubmit() {
      // var reg = /^[0-9]\d{5}(?!\d)$/g;
      // var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
      // var isMob = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
      // // var pattern = /^([1-9]{1})(\d{14}|\d{18})$/;
      // var bank = /^\d+$/;
      // if (!reg.test(this.formTable.email)) {
      //   this.$message.warning("邮编格式不正确，请重新填写");
      //   return;
      // }
      // // if (!isMob.test(this.formTable.Telephone) && !isPhone.test(this.formTable.Telephone)) {
      // //   this.$message.warning("请输入正确的电话");
      // //   return;
      // // }
      // if (!isMob.test(this.formTable.bossPhone)) {
      //   this.$message.warning("请输入正确的老板手机号");
      //   return;
      // }

      // // 正则验证
      // if (!bank.test(this.formTable.bankAccount)) {
      //   this.$message.warning("银行账号格式不正确，请重新填写");
      //   return;
      // }

      
      // if (!this.formTable.shopName) {
      //   this.shopNameShow = true;
      //   this.$message.warning("请输入客户全称");
      //   return;
      // }
      // if (!this.formTable.jcName) {
      //   this.jcNameShow = true;
      //   this.$message.warning("请输入客户简称");
      //   return;
      // }
      // if (this.formTable.jcName.length>10) {
      //   this.$message.warning("简称大于10个字");
      //   return
      // }
      if (
        !this.appendixFileId.id &&
        !this.businessLicenseId.id &&
        !this.taxFileId.id &&
        !this.mechanismFileId.id
      ) {
        this.$message.warning("请上传三证");
        return;
      }
      
      // if(!this.formTable.province){
      //   this.$message.warning("请选择所在地区");
      //   return
      // }
      // if (!this.formTable.RegistrationNumber) {
      //    this.$message.warning("请填写工商信息");
      //   return
      // }
      // if (!this.formTable.officeAddress) {
      //   this.$message.warning("请输入办公地址");
      //   return;
      // }
      // if (!this.formTable.NetworkType) {
      //   this.$message.warning("请选择网点类型");
      //   return;
      // }
      // if (!this.formTable.cityLevel) {
      //   this.$message.warning("请选择城市级别");
      //   return;
      // }
      // if (!this.formTable.MarketLevel) {
      //   this.$message.warning("请选择市场级别");
      //   return;
      // }
      // if (!this.formTable.channelLevel) {
      //   this.$message.warning("请选择渠道类别");
      //   return;
      // }
      // if (!this.formTable.channelLevelSegmentation) {
      //   this.$message.warning("请选择渠道类别细分");
      //   return;
      // }
      // if (!this.formTable.officeAddress) {
      //   this.$message.warning("请输入办公地址");
      //   return;
      // }

      // //浏览器缓存type=1
      setStoreUser1(1);

      // // 贸易关系视图数据重组
      if (this.newtableData.length == 0) {
        this.$message.warning(`请填写贸易关系数据!`);
        return;
      }
      if (this.newtableData.length > 0) {
        this.newtableData.forEach((item) => {
          this.tradeRelationVOList1.push({
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
         
        });
      }
      let data = {
        distributorInfoVO: {
          customerFullName: this.formTable.shopName, //商家全称
          customerShortName: this.formTable.jcName, //商家简称
          provinceId: this.formTable.province, //省份id
          // "provinceId": "350",//省份id
          cityId: this.formTable.city, //市id
          // "cityId": "307",//市id
          countyId: this.formTable.area, //区县id
          // "countyId": "183",//区县id
          townId: this.formTable.Township, //乡镇街道
          // "townId": "168",//乡镇街道
          officeAddress: this.formTable.officeAddress, //办公地址
          address: this.formTable.companyRegisteredAddress, //注册地址
          postalCode: this.formTable.email, //邮编
          networkTypeId: this.formTable.NetworkType, //网点类型id
          districtType: this.formTable.cityLevel, //城市级别
          marketLevelId: this.formTable.MarketLevel, //市场级别id
          bossTel: this.formTable.bossPhone, //老板手机号
          primaryChannelId: this.formTable.channelLevel, //渠道类别id
          subChannelId: this.formTable.channelLevelSegmentation, //渠道类别细分id
          bdId: Number(this.formTable.bdId),
          bu1Id: Number(this.formTable.bu1Id),
          businessRegistrationNo: this.formTable.RegistrationNumber, //工商注册号
          // taxCode: this.formTable.IdentificationNumber, //税务登记证/营业执照/身份证
          taxCode: this.formTable.IdentificationNumber, //税务登记证
          businessLicense: this.formTable.businessLicense, //营业执照
          idCard: this.formTable.idCard, //身份证
          enterpriseType: this.formTable.EnterpriseType, //企业类型
          unifiedSocialCreditCode: this.formTable.SocialUnifiedCredit, //统一社会信用码
          legalPerson: this.formTable.legalPerson, //法人
          majorPeople: this.formTable.corporateStockholder, //主要人员
          stockHolder: this.formTable.keyPersonnel, //公司股东
          bankName: this.formTable.bankName, //银行名称
          accountHolder: this.formTable.accountHolder, //账号持有人
          bankAccount: this.formTable.bankAccount, //银行账户
          threeInOneAttachment: this.appendixFileId, // 三证合一
          //  "threeInOneAttachment": {
          //   "id":1
          // },// 三证合一
          otherAttachmentList: this.otherFileId, // 其他附件
          tradeRelationVOList: this.tradeRelationVOList1, //贸易关系
        },
        id: 0,
        showSelect: true,
      };

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.tijiaoable = true;
          stepBuildStoreParamCheck(data).then((res) => {
            if(res.data.code ==  1){
              this.saveLoading = false;
              this.tradeRelationVOList1 = [];
              this.tijiaoable = false;
              this.$message.warning(res.data.msg?this.isContentEmpty(res.data.msg):'');
              return false
            }
            if(res.data.code!=0){
              this.saveLoading = false;
              // this.$message.success("提交成功");
              this.tradeRelationVOList1 = [];
              this.tijiaoable = false;
              return false
            }else{
              this.saveLoading = false;

              this.showModal();
              this.tradeRelationVOList1=[]
            }
          })
        } else {
          this.saveLoading = false;
          this.tradeRelationVOList1 = [];
          this.$message.warning("请将信息填写完整再提交");
          return;
        }
      });
      
      // this.showModal();
    },
    //编辑页面的提交
    saveSubmit1() {
      // var reg = /^[0-9]\d{5}(?!\d)$/g;
      // var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
      // var isMob = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
      // // var pattern = /^([1-9]{1})(\d{14}|\d{18})$/;
      // var bank = /^\d+$/;
      
      // if (!reg.test(this.formTable.email)) {
      //   this.$message.warning("邮编格式不正确，请重新填写");
      //   return;
      // }
      
      // if (!isMob.test(this.formTable.bossPhone)) {
      //   this.$message.warning("请输入正确的老板手机号");
      //   return;
      // }

      // // 正则验证
      // if (!bank.test(this.formTable.bankAccount)) {
      //   this.$message.warning("银行账号格式不正确，请重新填写");
      //   return;
      // }

      if (
        !this.appendixFileId.id &&
        !this.businessLicenseId.id &&
        !this.taxFileId.id &&
        !this.mechanismFileId.id
      ) {
        this.$message.warning("请上传三证");
        return;
      }
      // if (!this.formTable.shopName) {
      //   this.shopNameShow = true;
      //   this.$message.warning("请输入客户全称");
      //   return;
      // }
      // if (!this.formTable.jcName) {
      //   this.jcNameShow = true;
      //   this.$message.warning("请输入客户简称");
      //   return;
      // }
      // if (!this.formTable.officeAddress) {
      //   this.$message.warning("请输入办公地址");
      //   return;
      // }
      // if (!this.formTable.NetworkType) {
      //   this.$message.warning("请选择网点类型");
      //   return;
      // }
      // if (!this.formTable.cityLevel) {
      //   this.$message.warning("请选择城市级别");
      //   return;
      // }
      // if (!this.formTable.MarketLevel) {
      //   this.$message.warning("请选择市场级别");
      //   return;
      // }
      // if (!this.formTable.channelLevel) {
      //   this.$message.warning("请选择渠道类别");
      //   return;
      // }
      // if (!this.formTable.channelLevelSegmentation) {
      //   this.$message.warning("请选择渠道类别细分");
      //   return;
      // }
      // if (!this.formTable.officeAddress) {
      //   this.$message.warning("请输入办公地址");
      //   return;
      // }

   
      // // 贸易关系视图数据重组
      if (this.newtableData.length == 0) {
        this.$message.warning(`请填写贸易关系数据!`);
        return;
      }
      if (this.newtableData.length > 0) {
         
        this.newtableData.forEach((item) => {
           this.tradeRelationVOList1.push({
            id:item.id? item.id: "",
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
            superiorAgentId: this.tableData[0].agentId,
           });
      
         
        });
      }
      var bankAccountVOList = [];
      if (this.BankTableData.length > 0) {
        this.BankTableData.forEach(item => {
          bankAccountVOList.push({
            id:item.id ? item.id : "",
            bankId: item.bankId ? item.bankId : "",//银行id
            bankName: item.bankName ? item.bankName : "",//银行名称
            jointBankNo: item.bankNumber ? item.bankNumber : "",//联行号
            bankAccount: item.bankAccount ? item.bankAccount : "",//银行账号
            accountHolder: item.accountHolder ? item.accountHolder : "",//账号持有人
            isInvoiceBank: item.billingAccountId ? item.billingAccountId : ""//是否为开户行
          });
        });
      }
      let data = {
       
          id: this.editid,
          processInstanceId:this.formTable.processInstanceId,
          customerFullName: this.formTable.shopName, //商家全称
          customerShortName: this.formTable.jcName, //商家简称
          provinceId: this.formTable.province, //省份id
          // provinceId: 350,//省份id
          cityId: this.formTable.city, //市id
          // cityId: 307,//市id
          countyId: this.formTable.area, //区县id
          // countyId: 183,//区县id
          townId: this.formTable.Township, //乡镇街道
          // townId: 168,//乡镇街道
          officeAddress: this.formTable.officeAddress, //办公地址
          address: this.formTable.companyRegisteredAddress, //注册地址
          postalCode: this.formTable.email, //邮编
          networkTypeId: this.formTable.NetworkType, //网点类型id
          districtType: this.formTable.cityLevel, //城市级别
          marketLevelId: this.formTable.MarketLevel, //市场级别id
          bossTel: this.formTable.bossPhone, //老板手机号
          primaryChannelId: this.formTable.channelLevel, //渠道类别id
          subChannelId: this.formTable.channelLevelSegmentation, //渠道类别细分id
          bdId: Number(this.formTable.bdId),
          bu1Id: Number(this.formTable.bu1Id),
          businessRegistrationNo: this.formTable.RegistrationNumber, //工商注册号
          // taxCode: this.formTable.IdentificationNumber, //税务登记证/营业执照/身份证
          taxCode: this.formTable.IdentificationNumber, //税务登记证
          businessLicense: this.formTable.businessLicense, //营业执照
          idCard: this.formTable.idCard, //身份证
          enterpriseType: this.formTable.EnterpriseType, //企业类型
          unifiedSocialCreditCode: this.formTable.SocialUnifiedCredit, //统一社会信用码
          legalPerson: this.formTable.legalPerson, //法人
          majorPeople: this.formTable.corporateStockholder, //主要人员
          stockHolder: this.formTable.keyPersonnel, //公司股东
          bankName: this.formTable.bankName, //银行名称
          accountHolder: this.formTable.accountHolder, //账号持有人
          bankAccount: this.formTable.bankAccount, //银行账户
          threeInOneAttachment: this.appendixFileId, // 三证合一
          otherAttachmentList: this.otherFileId, // 其他附件
          tradeRelationVOList: this.tradeRelationVOList1, //贸易关系
          cisCode: this.formTable.cisCode,//CIS编码
          householdNatureId: this.formTable.householdNatureId,
          primaryLabelId: this.formTable.groupLevelLabel,//集团一级标签id
          secondLabelId: this.formTable.groupSecondaryLabel,//集团二级标签id
          thirdLabelId:this.formTable.thirdLabelId,// 集团三级
          largeAreaId: this.formTable.RegionId,//所属大区id
          registeredAddress: this.formTable.companyRegisteredAddress,
          telephone: this.formTable.Telephone,//电话
          accountGroupId: this.formTable.accountGroupId,//账户组id
          bankAccountVOList: bankAccountVOList,
      };
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.tijiaoable = true;
          flowStart(data).then((res) => {
            if (res.data.code == 0) {
              this.saveLoading = false;
              this.$message.success("提交成功");
              this.tradeRelationVOList1 = [];
              this.tijiaoable = false;
              // // 关闭窗口之前刷新父页面
              // window.opener.location.reload();
              this.timer = setTimeout(() => {
                //设置延迟执行
                this.goback();
              }, 2000);
              return;
            } else {
              this.saveLoading = false;
              this.$message.warning(res.data.msg);
              this.tradeRelationVOList1 = [];
              return;
            }
          });
        } else {
          this.saveLoading = false;
          this.tradeRelationVOList1 = [];
          this.$message.warning("请将信息填写完整再提交");
          return;
        }
      });

    },

    //新提交
    showModal() {
      this.visible1 = true;
    },
    // showModal1() {
    //   this.visible3 = true;
    // },
    //点击建店的确定按钮
    hideModal() {
      this.visible1 = false;
      if (this.newtableData.length > 0) {
        this.newtableData.forEach((item) => {
          this.tradeRelationVOList1.push({
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
          // debugger;
          this.tradeRelationVOList2.push({
            branchOfficeid: item.BranchOfficeid ? item.BranchOfficeid : "", //分公司id
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组code
            materialGroupBranchCompany2: item.MaterialGroup
              ? item.MaterialGroup
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
        });
          // debugger;
      }
      let data = {
        distributorInfoVO: {
          // "id": this.$route.query.id ? this.$route.query.id : 0,//主键id，新增时传0
          customerFullName: this.formTable.shopName, //商家全称
          // "customerFullName": '',//商家全称

          customerShortName: this.formTable.jcName, //商家简称
          // "customerShortName ": '',//商家简称

          provinceId: this.formTable.province, //省份id
          // "provinceId": '350',//省份id

          cityId: this.formTable.city, //市id
          // "cityId": '307',//市id

          countyId: this.formTable.area, //区县id
          // "countyId": '183',//区县id

          townId: this.formTable.Township, //乡镇街道
          // "townId": '168',//乡镇街道

          officeAddress: this.formTable.officeAddress, //办公地址
          // "officeAddress": '',//办公地址

          address: this.formTable.companyRegisteredAddress, //注册地址
          // "address": '',//注册地址

          postalCode: this.formTable.email, //邮编
          // "postalCode": '',//邮编

          networkTypeId: this.formTable.NetworkType, //网点类型id
          // "networkTypeId": '',//网点类型id

          districtType: this.formTable.cityLevel, //城市级别
          // "districtType": '',//城市级别

          marketLevelId: this.formTable.MarketLevel, //市场级别id
          // "marketLevelId": '',//市场级别id

          bossTel: this.formTable.bossPhone, //老板手机号
          // "bossTel": '',//老板手机号

          primaryChannelId: this.formTable.channelLevel, //渠道类别id
          // "primaryChannelId": '',//渠道类别id

          subChannelId: this.formTable.channelLevelSegmentation, //渠道类别细分id
          // "subChannelId": '',//渠道类别细分id
          
          bdId: Number(this.formTable.bdId),
          bu1Id: Number(this.formTable.bu1Id),

          businessRegistrationNo: this.formTable.RegistrationNumber, //工商注册号
          // "businessRegistrationNo": '',//工商注册号

          taxCode: this.formTable.IdentificationNumber, //税务登记证/营业执照/身份证
          // "taxCode": '',//税务登记证/营业执照/身份证

          enterpriseType: this.formTable.EnterpriseType, //企业类型
          // "enterpriseType": '',//企业类型

          unifiedSocialCreditCode: this.formTable.SocialUnifiedCredit, //统一社会信用码
          // "unifiedSocialCreditCode": '',//统一社会信用码

          legalPerson: this.formTable.legalPerson, //法人
          // "legalPerson": '',//法人
          majorPeople: this.formTable.corporateStockholder, //主要人员

          stockHolder: this.formTable.keyPersonnel, //公司股东

          bankName: this.formTable.bankName, //银行名称
          // "bankName": '',//银行名称

          accountHolder: this.formTable.accountHolder, //账号持有人
          // "accountHolder": '',//账号持有人

          bankAccount: this.formTable.bankAccount, //银行账户
          // "bankAccount": '',//银行账户

          threeInOneAttachment: this.appendixFileId, // 三证合一
          // "threeInOneAttachment": {
          //   "id":1
          // },// 三证合一

          otherAttachmentList: this.otherFileId, // 其他附件
          // "otherAttachmentList": {"id":1}, // 其他附件

          tradeRelationVOList: this.tradeRelationVOList2, //贸易关系
          // "tradeRelationVOList": [{
          //   "materialGroupBranchCompany":"20160119008-176",
          //   "customerManager": "123",
          //   "customerManagerTel":"123"
          // }],//贸易关系

          /* 以下数据是旧的不用发给后端 */
          // "enterpriseState": this.formTable.qyStatus,//企业状态
          // "customerCategoryList": this.formTable.MerchantCategory,//商家类别
          // "sapSearch2": "",

          // "townsId": "",
          // "majorPeople": this.formTable.keyPersonnel,//主要人员
          // "taxTypeId": this.formTable.taxClassification,//税分类id
          // "stockHolder": this.formTable.corporateStockholder,//公司股东
          // // "marketingCenterInformationId": "0",//弹窗选择id
          // "applyReason": this.formTable.applyReason,//申请原因
          // "businessLicenseAttachment": this.businessLicenseId,//营业执照
          // "taxRegistrationCertificateAttachment": this.taxFileId,// 税务登记
          // "organizationCodeCertificateAttachment": this.mechanismFileId,//组织机构代码证附件id
          // "salesViewVOList": salesViewVOList,
          // "companyInfoViewVOList": companyInfoViewVOList,
          // // annualContractAmount: Number(this.formTable.annualContractAmount),
          // cooperativeNatureId: this.formTable.cooperativeNatureId,
        },
        custShopInfoCheckDto:this.huancundata!=[]?this.huancundata[0]?.custShopInfoCheckDto:"",
        wareHouseInfoDto:this.huancundata!=[]?this.huancundata[0]?.wareHouseInfoDto:"",
       
        id: 0,
        showSelect: true,
      };
          // debugger;
      setStoreUser(data);
      // debugger;

      setStoreUser1(2);
      setqystatus(this.formTable.qyStatus);
      this.$router.push({path:"/distributionStores/addDistributionStore",query:{category:"step"}});
    },

    //点击建店模态框的取消按钮
    hideModal1() {
      // this.$emit("update:visible", false);
      // this.$forceUpdate()
      this.tijiaoable = false;
      this.visible1=false
      this.visible2 = true;
    },
    
    // 点击建仓模态框的确定按钮
    hideModal2() {
      this.visible2 = false;
      if (this.newtableData.length > 0) {
        this.newtableData.forEach((item) => {
          this.tradeRelationVOList1.push({
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
          this.tradeRelationVOList2.push({
            branchOfficeid: item.BranchOfficeid ? item.BranchOfficeid : "", //分公司id
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组code
            materialGroupBranchCompany2: item.MaterialGroup
              ? item.MaterialGroup
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
        });
      }
      let data = {
        distributorInfoVO: {
          customerFullName: this.formTable.shopName, //商家全称
          customerShortName: this.formTable.jcName, //商家简称
          provinceId: this.formTable.province, //省份id
          cityId: this.formTable.city, //市id
          countyId: this.formTable.area, //区县id
          townId: this.formTable.Township, //乡镇街道
          // "provinceId": '350',//省份id
          // "cityId": '307',//市id
          // "countyId": '183',//区县id
          // "townId": '168',//乡镇街道
          officeAddress: this.formTable.officeAddress, //办公地址
          address: this.formTable.companyRegisteredAddress, //注册地址
          postalCode: this.formTable.email, //邮编
          networkTypeId: this.formTable.NetworkType, //网点类型id
          districtType: this.formTable.cityLevel, //城市级别
          marketLevelId: this.formTable.MarketLevel, //市场级别id
          bossTel: this.formTable.bossPhone, //老板手机号
          primaryChannelId: this.formTable.channelLevel, //渠道类别id
          subChannelId: this.formTable.channelLevelSegmentation, //渠道类别细分id
          bdId: Number(this.formTable.bdId),
          bu1Id: Number(this.formTable.bu1Id),
          businessRegistrationNo: this.formTable.RegistrationNumber, //工商注册号
          taxCode: this.formTable.IdentificationNumber, //税务登记证/营业执照/身份证
          enterpriseType: this.formTable.EnterpriseType, //企业类型
          unifiedSocialCreditCode: this.formTable.SocialUnifiedCredit, //统一社会信用码
          legalPerson: this.formTable.legalPerson, //法人
          majorPeople: this.formTable.corporateStockholder, //主要人员
          stockHolder: this.formTable.keyPersonnel, //公司股东
          bankName: this.formTable.bankName, //银行名称
          accountHolder: this.formTable.accountHolder, //账号持有人
          bankAccount: this.formTable.bankAccount, //银行账户
          threeInOneAttachment: this.appendixFileId, // 三证合一
          //  "threeInOneAttachment": {
          //   "id":1
          // },// 三证合一
          otherAttachmentList: this.otherFileId, // 其他附件
          tradeRelationVOList: this.tradeRelationVOList2, //贸易关系
        },
        wareHouseInfoDto:this.huancundata!=[]?this.huancundata[0]?.wareHouseInfoDto:"",
        id: 0,
        showSelect: true,
      };
      setStoreUser(data);
      setqystatus(this.formTable.qyStatus);
      setStoreUser1(3);
      this.$router.push({path:"/distributorWarehouse/addDistributorWarehouse",query:{category:'step'}});
    },
    // 点击建仓的取消按钮
    hideModal3() {
      this.visible3 = true;
      this.visible2=false
    },
    //点击提交确定按钮
    hideModal4() {
      const hide = this.$message.loading("正在提交请稍等..", 0);
      setTimeout(hide, 3000);
      if (this.newtableData.length > 0) {
        this.newtableData.forEach((item) => {
          this.tradeRelationVOList1.push({
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
          this.tradeRelationVOList2.push({
            branchOfficeid: item.BranchOfficeid ? item.BranchOfficeid : "", //分公司id
            materialGroupBranchCompany: item.MaterialGroupcode
              ? item.MaterialGroupcode
              : "", //物料组code
            materialGroupBranchCompany2: item.MaterialGroup
              ? item.MaterialGroup
              : "", //物料组
            customerManager: item.MerchantManagerContact
              ? item.MerchantManagerContact
              : "", //商家经理联系人
            customerManagerTel: item.MerchantManagerContactPhone
              ? item.MerchantManagerContactPhone
              : "", //商家经理联系电话
          });
        });
      }
      let data = {
        distributorInfoVO: {
          customerFullName: this.formTable.shopName, //商家全称
          customerShortName: this.formTable.jcName, //商家简称
          provinceId: this.formTable.province, //省份id
          // "provinceId": "350",//省份id
          cityId: this.formTable.city, //市id
          // "cityId": "307",//市id
          countyId: this.formTable.area, //区县id
          // "countyId": "183",//区县id
          townId: this.formTable.Township, //乡镇街道
          // "townId": "168",//乡镇街道
          officeAddress: this.formTable.officeAddress, //办公地址
          address: this.formTable.companyRegisteredAddress, //注册地址
          postalCode: this.formTable.email, //邮编
          networkTypeId: this.formTable.NetworkType, //网点类型id
          districtType: this.formTable.cityLevel, //城市级别
          marketLevelId: this.formTable.MarketLevel, //市场级别id
          bossTel: this.formTable.bossPhone, //老板手机号
          primaryChannelId: this.formTable.channelLevel, //渠道类别id
          subChannelId: this.formTable.channelLevelSegmentation, //渠道类别细分id
          bdId: Number(this.formTable.bdId),
          bu1Id: Number(this.formTable.bu1Id),
          businessRegistrationNo: this.formTable.RegistrationNumber, //工商注册号
          taxCode: this.formTable.IdentificationNumber, //税务登记证/营业执照/身份证
          enterpriseType: this.formTable.EnterpriseType, //企业类型
          unifiedSocialCreditCode: this.formTable.SocialUnifiedCredit, //统一社会信用码
          legalPerson: this.formTable.legalPerson, //法人
          majorPeople: this.formTable.corporateStockholder, //主要人员
          stockHolder: this.formTable.keyPersonnel, //公司股东
          bankName: this.formTable.bankName, //银行名称
          accountHolder: this.formTable.accountHolder, //账号持有人
          bankAccount: this.formTable.bankAccount, //银行账户
          threeInOneAttachment: this.appendixFileId, // 三证合一
          //  "threeInOneAttachment": {
          //   "id":1
          // },// 三证合一
          otherAttachmentList: this.otherFileId, // 其他附件
          tradeRelationVOList: this.tradeRelationVOList1, //贸易关系
        },
        id: 0,
        showSelect: true,
      };
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.tijiaoable = true;
          tmpSave(data).then((res) => {
            if (res.data.code == 0) {
              this.saveLoading = false;
              this.$message.success("提交成功");
              this.tijiaoable = false;
              delStoreUser();
              delStoreUser1();
              // // 关闭窗口之前刷新父页面
              // window.opener.location.reload();
              this.timer = setTimeout(() => {
                //设置延迟执行
                this.goback();
              }, 2000);
              return;
            } else {
              this.saveLoading = false;
              this.$message.warning(res.data.msg);
              return;
            }
          });
        } else {
          this.saveLoading = false;
          this.$message.warning("请将信息填写完整再提交");
          return;
        }
      });
      this.visible3 = false;
    },
    hideModal5() {
      this.visible3 = false;
    },
    confirm() {
      this.$confirm({
        title: "Confirm",
        content: "Bla bla ...",
        okText: "确认",
        cancelText: "取消",
      });
    },

    // 贸易关系---开始

    // 获取分公司
    getFGS() {
      let data = {};
      findBranchCompanyCombobox(data).then((res) => {
        if (res.data.code == 0) {
          this.branchesList = res.data.list;
          this.orgList = res.data.list;
          this.companyList = res.data.list;
        }
      });
    },
    // 更改分公司
    handleChangebranches(e, record) {
      record.branchId = e;
      let obj = this.branchesList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.branchName = obj.name;
      record.materialId = "";
      record.materialName = "";
      record.CooperationId = "";
      record.CooperationName = "";
      this.$forceUpdate();
      this.getWLZ(e, record);
    },
    // 获取物料组--旧
    // getWLZ(e, record) {
    //   let data = {
    //     branchCompanyId: e
    //   };
    //   findMaterialGroupCombobox(data).then(res => {
    //     if (res.data.code == 0) {
    //       record.materialList = res.data.list;
    //     }
    //   });
    // },
    // 获取物料组--新
    async getMaterialCompany() {
      await MaterialCompany().then((res) => {
        this.getallMaterialGroup.push(res.data.list);
      });
    },
    //更改物料组
    async handleChangematerial(e, record, type) {
      record.materialId = e;
      let obj = record.materialList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.materialName = obj.name;
      record.CooperationId = "";
      record.CooperationName = "";
      this.$forceUpdate();
      if (type == "my") {
        // 获取合作模式 赋值默认第一个 合作模式id和name赋值
        let res = await this.getHZMs(record.materialId, record.branchId);
        if (res && res.data.code == 0) {
          record.CooperationTypeList = res.data.list;
          record.CooperationId = res.data.list[0].code;
          record.CooperationName = res.data.list[0].name;
          this.$forceUpdate();
        }
      }
    },

    // 获取合作模式
    getHZMs(materialId, branchId) {
      let data = {
        orgId: branchId,
        materialGroupId: materialId,
      };
      return findCooperationModel(data);
    },
    // 更改营销模式
    handleChangeMarketingType(e, record) {
      record.MarketingTypeId = e;
      let obj = this.MarketingModelList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.MarketingTypeName = obj.name;
    },
    // 获取上级代理  此处为写死数据 从登录接口中获取
    getAgentList() {
      this.agentList.push({
        code: this.$store.state.user.userInfo.customer.id,
        name: this.$store.state.user.userInfo.customer.customerName,
      });
    },
    // // 上级代理模糊搜索
    // search() {
    // },
    // // 模糊搜索上级代理
    // getagentList(value, recode) {
    //   this.selctRow = recode;
    //   this.agentSearchStr = value;
    //   let data = {
    //     searchKeyWords: this.agentSearchStr,
    //     isSpecialShop: "212400"
    //   };
    //
    //   this.selctRow.fetching = true;
    //   this.$forceUpdate();
    //   getShopList(data).then(res => {
    //     let data;
    //     if (typeof res.data == "string") {
    //       data = JSON.parse(res.data);
    //       this.selctRow.fetching = false;
    //     } else {
    //       data = res.data;
    //     }
    //     this.selctRow.fetching = false;
    //     this.$forceUpdate();
    //     this.agentList = [...new Set(data.data)]; //缓存列表数据，前端分页
    //   });
    // },
    // //更改上级代理
    // changeAgent(value, row) {
    //   this.agentSearchStr = value;
    //   //
    //   row.agentId = value;
    //   //  调用
    //   let obj = this.agentList.find((i) => {//listData就是上面的数据源
    //     return i.code === value;//筛选出匹配数据
    //   });
    //   // 门店名称
    //   row.agentName = obj.name;
    //
    //   row.CooperationId = 0;
    //
    //   this.$forceUpdate();
    // },
    // 获取业务类型
    getYWLX() {
      let data = {
        pid: 157007700,
      };
      focusonremarkplz(data).then((res) => {
        this.businessList = res.data.list;
      });
    },
    // 更改业务类型
    handleChangebusiness(e, recode) {
      recode.businessId = e;
      let obj = this.businessList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      recode.businesName = obj.name;
    },

    // 更改合作状态
    handleChangeCooperationStatus(e, recode) {
      recode.CooperationStatusId = e;
      let obj = this.CooperationStatusList.find((i) => {
        //listData就是上面的数据源
        return i.id === e; //筛选出匹配数据
      });
      recode.CooperationStatusName = obj.name;
    },
    // 时间更改
    handleTimeChange(e, defaultTime, record) {
      // 要求完成时间
      record.freezeTime = defaultTime;
    },
    // ----------------贸易关系结束-----------------------------

    //   ================销售视图===============================
    // 修改组织
    handleChangeOrg(e, record) {
      record.orgId = e;
      let obj = this.orgList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.orgName = obj.name;
      this.getSaleAdress(e, record);
      record.saleAddressId = "";
      record.saleAddressName = "";
      this.$forceUpdate();
    },
    // 获取销售地址
    getSaleAdress(e, record) {
      let data = {
        orgId: e,
      };
      findSalesAreaCombobox(data).then((res) => {
        record.SaleAddressList = res.data.list;
      });
    },
    // 修改地址
    handleChangeSaleAddress(e, record) {
      record.saleAddressId = e;
      let obj = record.SaleAddressList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.saleAddressName = obj.name;
      this.$forceUpdate();
    },
    // 获取价格组
    getPriceList() {
      let data = {
        pid: 372271055,
      };
      focusonremarkplz(data).then((res) => {
        this.priceList = res.data.list;
      });
    },
    //   修改价格组
    handleChangePrice(e, record) {
      record.priceId = e;
      let obj = this.priceList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.priceName = obj.name;
      this.$forceUpdate();
    },
    // 获取付款条件
    getpayList() {
      let data = {
        pid: 372271054,
      };
      focusonremarkplz(data).then((res) => {
        this.payList = res.data.list;
        this.TermPaymenList = res.data.list;
      });
    },
    //修改付款条件
    handleChangePay(e, record) {
      record.payId = e;
      let obj = this.payList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.payName = obj.name;
      this.$forceUpdate();
    },
    // 更改冻结状态
    handleChangesaleFreeze(e, record) {
      record.saleFreezeId = e;
      let obj = this.saleFreezeList.find((i) => {
        //listData就是上面的数据源
        return i.id === e; //筛选出匹配数据
      });
      record.saleFreezeName = obj.name;
    },

    // ====================公司视图===============================
    //  公司名称
    handleChangeCompany(e, record) {
      record.companyId = e;
      let obj = this.companyList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.companyName = obj.name;
      this.$forceUpdate();
    },
    // 获取统御科目
    getSubject() {
      let data = {
        pid: 372271052,
      };
      focusonremarkplz(data).then((res) => {
        this.SubjectList = res.data.list;
      });
    },
    // 修改统御科目
    handleChangeSubject(e, record) {
      record.SubjectId = e;
      let obj = this.SubjectList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.SubjectName = obj.name;
      this.$forceUpdate();
    },
    // 获取选择规则
    getRulesList() {
      let data = {
        pid: 372271053,
      };
      focusonremarkplz(data).then((res) => {
        this.RulesList = res.data.list;
      });
    },
    // 选择规则
    handleChangeRules(e, record) {
      record.RulesId = e;
      let obj = this.RulesList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.RulesName = obj.name;
      this.$forceUpdate();
    },

    // 付款条件
    handleChangeTermPayment(e, record) {
      record.TermPaymenId = e;
      let obj = this.TermPaymenList.find((i) => {
        //listData就是上面的数据源
        return i.code === e; //筛选出匹配数据
      });
      record.TermPaymenName = obj.name;
      this.$forceUpdate();
    },
    //记账冻结标识
    handleChangeBookkeepingFreeze(e, record) {
      record.BookkeepingFreezeId = e;
      let obj = this.BookkeepingFreezeList.find((i) => {
        //listData就是上面的数据源
        return i.id === e; //筛选出匹配数据
      });
      record.BookkeepingFreezeName = obj.name;
      this.$forceUpdate();
    },
    //  ================银行账号==================
    // 上级代理模糊搜索
    searchbank() {},
    // 模糊搜索上级代理
    getbanktList(value, recode) {
      this.selctRow = recode;
      this.bankSearchStr = value;
      if (this.bankSearchStr.length >= 2) {
        let data = {
          searchKeywords: this.bankSearchStr,
        };

        this.selctRow.fetching = true;
        this.$forceUpdate();
        findBankInfoCombobox(data).then((res) => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
            this.selctRow.fetching = false;
          } else {
            data = res.data;
          }
          this.selctRow.fetching = false;
          this.$forceUpdate();
          recode.bankList = [...new Set(data.list)]; //缓存列表数据，前端分页
        });
      } else {
        this.$message.warning("至少输入两个字进行搜索");
      }
    },
    //更改上级代理
    async changebank(value, row) {
      this.bankSearchStr = value;
      row.bankId = value;
      //  调用
      let obj = row.bankList.find((i) => {
        //listData就是上面的数据源
        return i.code === value; //筛选出匹配数据
      });
      row.bankName = obj.name;
      let res = await this.getBnakNUM(row.bankName);
      // 联行号 银行名称联动出来
      if (res && res.data.code == 0) {
        row.bankNumber = res.data.data.interBankNumber;
      } else {
        row.bankNumber = "";
      }

      this.$forceUpdate();
    },
    // 查询银联号
    getBnakNUM(e) {
      let data = {
        bankName: e,
      };
      return findJointBankNumber(data);
      // findJointBankNumber(data).then(res=>{
      //
      // })
    },
    // 是否为开票账户
    handleChangebillingAccount(e, record) {
      record.billingAccountId = e;
      let obj = this.billingAccountList.find((i) => {
        //listData就是上面的数据源
        return i.id === e; //筛选出匹配数据
      });
      record.billingAccountName = obj.name;
      this.$forceUpdate();
    },
    //  ==========商家历史关系==========================
    //  上一任商家编码/名称-
    handleChangeMerchant(e, record) {
      record.MerchantId = e;
      let obj = this.MerchantList.find((i) => {
        //listData就是上面的数据源
        return i.id === e; //筛选出匹配数据
      });
      record.MerchantName = obj.name;
    },
    // 开始时间
    handleStartTimeChange(e, defaultTime, record) {
      record.StartTime = defaultTime;
    },
    // 结束时间
    handleendTimeChange(e, defaultTime, record) {
      record.endTime = defaultTime;
    },
  },
};
